<template>
  <div class="indexContainer">
    <!-- PC端 -->
    <div v-if="isPcShow">
      <!-- 轮播图 -->
      <div class="carouselImgs">
        <el-carousel>
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <img
              v-if="item.attributes"
              :src="imgUrl + item.attributes.image.data.attributes.url"
              alt
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 搜索框 -->
      <div class="searchValue">
        <el-row type="flex" class="row-bg" justify="center">
          <el-col :span="2" class="selectSeaView">
            <el-select
              v-model="selectValue"
              placeholder="请选择"
              @change="selectChange"
            >
              <el-option
                v-for="item in searchOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="13">
            <div class="searchIpt">
              <el-input
                placeholder="请输入内容"
                v-model="searchValue"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="3">
            <div class="searchBtn">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="toSearchPage"
                >立即搜索</el-button
              >
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 新闻模块 -->
      <div class="newView">
        <div class="newMenusView" @mouseleave="mouseleaveMenus">
          <!-- 菜单 -->
          <div class="newMenus" @mouseenter="mouseenterMenus">
            <div class="newTitle">
              <div class="newTitleCH">{{ newObj.title }}</div>
              <div class="newTitleEG">NEWS CENTER</div>
            </div>
            <div class="newListView" v-if="newObj.items">
              <div
                class="newList"
                v-for="(item, index) in newObj.items.data"
                :key="index"
                @mouseenter="mouseenterList"
                @mouseleave="mouseleaveList"
              >
                {{ item.attributes.title }}
              </div>
            </div>
          </div>
          <!-- 列表 -->
          <div class="newdetailView">
            <div class="newsSwiper">
              <el-carousel v-if="newsSwiperList.length > 0">
                <el-carousel-item
                  class="carouselView"
                  v-for="(item, index) in newsSwiperList"
                  :key="index"
                  @click.native="detailInfoClick(item, 'news')"
                >
                  <img
                    class="newsImg"
                    v-if="item.attributes"
                    :src="
                      imgUrl + item.attributes.featureImage.data.attributes.url
                    "
                    alt
                  />
                  <div class="newsTitle">
                    <div class="text" v-if="item.attributes">
                      {{ item.attributes.articleTitle }}
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
              <div class="listEmpty carouselViewNo" v-else>
                <div class="noDataView">暂无图片…</div>
              </div>
            </div>
            <div class="newsDetailListValue" v-if="newsDetailList.length > 0">
              <div
                class="newsDetailList"
                v-for="(item, index) in newsDetailList"
                :key="index"
                @click="detailInfoClick(item, 'news')"
              >
                <div class="newTimer">
                  <img src="../../src/assets/icons/虚线.png" alt="" />
                  <div class="newTimerD">
                    <span>{{ item.attributes.dayr }}</span>
                    <span>{{ item.attributes.timer }}</span>
                  </div>
                </div>
                <div class="newText">
                  <div class="newTextTitle">
                    {{ item.attributes.articleTitle }}
                  </div>
                  <div class="newTextDesc">{{ item.attributes.contStr }}</div>
                </div>
              </div>
            </div>
            <div class="listEmpty newsDetailListValue" v-else>
              <img src="../assets/icons/nodata.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <!-- 功能与科室 -->
      <div class="funDepView">
        <!-- 功能 -->
        <div class="funView">
          <div class="funOneView">
            <div class="funListView">
              <div class="funList" @click="funClick('zy')">
                <img src="../assets/images/home_yygh.png" alt="" />
                <div class="blackDiv"></div>
                <div class="funTitle">预约挂号</div>
                <div class="funDesc">手机挂号 方便快捷</div>
              </div>
              <div class="funList" @click="funClick('hlw')">
                <img src="../assets/images/home_hlwyy.png" alt="" />
                <div class="blackDiv"></div>
                <div class="funTitle">互联网医院</div>
                <div class="funDesc">复诊患者 问诊开药</div>
              </div>
              <div class="funList">
                <img src="../assets/images/home_ynfq.png" alt="" />
                <div class="blackDiv"></div>
                <div class="funTitle">院内分区</div>
                <div class="funDesc">清晰导览 真诚服务</div>
              </div>
              <div class="funList" @click="funClick('dh')">
                <img src="../assets/images/home_dh.png" alt="" />
                <div class="blackDiv"></div>
                <div class="funTitle">来院导航</div>
                <div class="funDesc">精准指引 轻松就医</div>
              </div>
            </div>
            <div class="funImgView">
              <img src="../assets/images/home_fun.png" alt="" />
            </div>
          </div>
        </div>
        <!-- 科室 -->
        <div class="deptView">
          <div class="deptTitle"><span>科室</span><span>介绍</span></div>
          <div class="deptTitleDesc">DEPARTMENT INTRODUCTION</div>
          <div class="deptTitleIntro">
            新疆维吾尔自治区人民医院，位于中国新疆乌鲁木齐市天池路91号
          </div>
          <div class="deptMajor" @mouseleave="mouseleaveDep">
            <!-- 重点科 -->
            <div
              class="deptMajListActice"
              v-if="depZdShow"
              @mouseleave="mouseleaveZD"
            >
              <div class="deptMajTitleAct">
                <img src="../assets/images/h_dep_zdA.png" alt="" /><span>{{
                  deptList[0].name
                }}</span>
              </div>
              <div class="deptMaj-car">
                <!-- <el-carousel> -->
                <!-- <el-carousel-item class="carouselView" v-for="(item, index) in newsSwiperList"
                                        :key="item + index"> -->
                <div class="deptListView" v-if="deptDetailList.length > 0">
                  <div
                    class="deptList"
                    @click="deptdetailClick(item)"
                    v-for="(item, index) in deptDetailList"
                    :key="index"
                  >
                    <div class="deptListName">{{ item.attributes.name }}</div>
                    <img src="../assets/images/arrow.png" alt="" />
                  </div>
                </div>
                <div class="listEmpty" v-else>
                  <img src="../assets/icons/nodata.png" alt="" />
                </div>
                <!-- </el-carousel-item>
                                </el-carousel> -->
              </div>
            </div>
            <div class="deptMajList" v-else @mouseenter="mouseenterZD">
              <img src="../assets/images/h_dep_zd.png" alt="" />
              <div class="deptMajTitle">{{ deptList[0].name.slice(0, 4) }}</div>
              <div class="deptMajTitle">{{ deptList[0].name.slice(4, 8) }}</div>
            </div>
            <!-- 内科 -->
            <div
              class="deptMajListActice"
              v-if="depNkShow"
              @mouseleave="mouseleaveNK"
            >
              <div class="deptMajTitleAct">
                <img src="../assets/images/h_dep_nkA.png" alt="" /><span>{{
                  deptList[1].name
                }}</span>
              </div>
              <div class="deptMaj-car">
                <!-- <el-carousel>
                                    <el-carousel-item class="carouselView" v-for="(item, index) in newsSwiperList"
                                        :key="index"> -->
                <div class="deptListView" v-if="deptDetailList.length > 0">
                  <div
                    class="deptList"
                    @click="deptdetailClick(item)"
                    v-for="(item, index) in deptDetailList"
                    :key="index"
                  >
                    <div class="deptListName">{{ item.attributes.name }}</div>
                    <img src="../assets/images/arrow.png" alt="" />
                  </div>
                </div>
                <div class="listEmpty" v-else>
                  <img src="../assets/icons/nodata.png" alt="" />
                </div>
                <!-- </el-carousel-item>
                                </el-carousel> -->
              </div>
            </div>
            <div class="deptMajList" v-else @mouseenter="mouseenterNK">
              <img src="../assets/images/h_dep_nk.png" alt="" />
              <div class="deptMajTitle">{{ deptList[1].name }}</div>
            </div>
            <!-- 外科 -->
            <div
              class="deptMajListActice"
              v-if="depWkShow"
              @mouseleave="mouseleaveWK"
            >
              <div class="deptMajTitleAct">
                <img src="../assets/images/h_dep_wkA.png" alt="" /><span>{{
                  deptList[2].name
                }}</span>
              </div>
              <div class="deptMaj-car">
                <!-- <el-carousel>
                                    <el-carousel-item class="carouselView" v-for="(item, index) in newsSwiperList"
                                        :key="index"> -->
                <div class="deptListView" v-if="deptDetailList.length > 0">
                  <div
                    class="deptList"
                    @click="deptdetailClick(item)"
                    v-for="(item, index) in deptDetailList"
                    :key="index"
                  >
                    <div class="deptListName">{{ item.attributes.name }}</div>
                    <img src="../assets/images/arrow.png" alt="" />
                  </div>
                </div>
                <div class="listEmpty" v-else>
                  <img src="../assets/icons/nodata.png" alt="" />
                </div>
                <!-- </el-carousel-item>
                                </el-carousel> -->
              </div>
            </div>
            <div class="deptMajList" v-else @mouseenter="mouseenterWK">
              <img src="../assets/images/h_dep_wk.png" alt="" />
              <div class="deptMajTitle">{{ deptList[2].name }}</div>
            </div>
            <!-- 医技科 -->
            <div
              class="deptMajListActice"
              v-if="depYjShow"
              @mouseleave="mouseleaveYJ"
            >
              <div class="deptMajTitleAct">
                <img src="../assets/images/h_dep_yjA.png" alt="" /><span>{{
                  deptList[3].name
                }}</span>
              </div>
              <div class="deptMaj-car">
                <!-- <el-carousel>
                                    <el-carousel-item class="carouselView" v-for="(item, index) in newsSwiperList"
                                        :key="index"> -->
                <div class="deptListView" v-if="deptDetailList.length > 0">
                  <div
                    class="deptList"
                    @click="deptdetailClick(item)"
                    v-for="(item, index) in deptDetailList"
                    :key="index"
                  >
                    <div class="deptListName">{{ item.attributes.name }}</div>
                    <img src="../assets/images/arrow.png" alt="" />
                  </div>
                </div>
                <div class="listEmpty" v-else>
                  <img src="../assets/icons/nodata.png" alt="" />
                </div>
                <!-- </el-carousel-item>
                                </el-carousel> -->
              </div>
            </div>
            <div class="deptMajList" v-else @mouseenter="mouseenterYJ">
              <img src="../assets/images/h_dep_yj.png" alt="" />
              <div class="deptMajTitle">{{ deptList[3].name }}</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 专家 -->
      <div class="expertView">
        <div class="expertTitle"><span>专家</span><span>介绍</span></div>
        <div class="expertTitleDesc">EXPERT INTRODUCTION</div>
        <div class="expertTiView">
          <div
            :class="expTabActive2 ? 'expertTiListActive' : 'expertTiList'"
            @click="expTabActiveFun2"
          >
            <img
              :src="
                expTabActive2
                  ? require('../assets/images/h_zj_xkA.png')
                  : require('../assets/images/h_zj_xk.png')
              "
              alt=""
            /><span>学术带头人</span>
          </div>
          <div
            :class="expTabActive1 ? 'expertTiListActive' : 'expertTiList'"
            @click="expTabActiveFun1"
          >
            <img
              :src="
                expTabActive1
                  ? require('../assets/images/h_zj_xsA.png')
                  : require('../assets/images/h_zj_xs.png')
              "
              alt=""
            /><span>学科带头人</span>
          </div>
          <div
            :class="expTabActive3 ? 'expertTiListActive' : 'expertTiList'"
            @click="expTabActiveFun3"
          >
            <img
              :src="
                expTabActive3
                  ? require('../assets/images/h_zj_lc.png')
                  : require('../assets/images/h_zj_lc.png')
              "
              alt=""
            /><span>临床专家</span>
          </div>
        </div>
        <div class="expertSwiView">
          <el-carousel>
            <el-carousel-item
              class="carouselView"
              v-for="(item, index) in zjjsSwiperList"
              :key="index"
            >
              <div class="expertListView">
                <div
                  class="expertList"
                  v-for="(ite, ind) in item"
                  :key="ind"
                  @click="expertDetailClick(ite)"
                >
                  <div class="expertListLef">
                    <img
                      class="expertImg"
                      v-if="ite.attributes.photo.data"
                      :src="imgUrl + ite.attributes.photo.data.attributes.url"
                      alt=""
                    />
                    <div v-else class="imgNo">暂无照片</div>
                  </div>
                  <div class="expertListRig">
                    <div class="expertName">
                      {{ ite.attributes.doctorName }}
                      <!-- <span>心血管内科</span> -->
                    </div>
                    <div class="expertZc">
                      {{ ite.attributes.doctorTitle }}
                    </div>
                    <div class="expertSc">{{ ite.attributes.expertise }}</div>
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <!-- 健康宣教 -->
      <div class="healthPropView">
        <div class="healthView-w">
          <div class="healthView">
            <div class="healthTitle">
              <div class="healthTitle"><span>健康</span><span>宣教</span></div>
              <div class="healthTitleDesc">HEALTH EDUCATION</div>
            </div>
            <div
              class="healthDetailListAct"
              @click="detailInfoClick(healthListAll[0], 'news')"
            >
              <div class="healthTimerAct">
                <img src="../../src/assets/icons/虚线.png" alt="" />
                <div class="healthTimerDAct">
                  <span v-if="healthListAll[0]">{{
                    healthListAll[0].attributes.dayr
                  }}</span>
                  <span v-if="healthListAll[0]">{{
                    healthListAll[0].attributes.timer
                  }}</span>
                </div>
              </div>
              <div class="healthTextAct">
                <div class="healthTextTitleAct" v-if="healthListAll[0]">
                  {{ healthListAll[0].attributes.articleTitle }}
                </div>
                <div class="healthTextDescAct" v-if="healthListAll[0]">
                  {{ healthListAll[0].attributes.contStr }}
                </div>
              </div>
            </div>
            <div
              class="healthDetailList"
              v-for="(item, index) in healthList"
              :key="index"
              @click="detailInfoClick(item, 'news')"
            >
              <div class="healthTimer">
                <img src="../../src/assets/icons/yq.png" alt="" />
              </div>
              <div class="healthText">
                <div class="healthTextTitle">
                  {{ item.attributes.articleTitle }}
                </div>
              </div>
            </div>
          </div>
          <div class="healthImgView">
            <div class="hea_Img_View">
              <img src="../assets/images/h_jkxj.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <!-- 弹框 -->
      <el-dialog
        title=""
        :visible.sync="dialogVisible"
        width="40%"
        :before-close="handleClose"
      >
        <div class="eqCodeDiv">
          <div class="eqImg">
            <img v-if="wxzyShow" src="../assets/icons/wxzy.png" alt />
            <img v-else src="../assets/icons/hlweq.png" alt />
          </div>
          <div class="eqtext" v-if="wxzyShow">
            <div>使用手机微信，扫描二维码</div>
            <div>进入新疆人民医院掌上医院小程序</div>
            <div>手机挂号、缴费退费、查看报告</div>
          </div>
          <div class="eqtext" v-else>
            <div>使用手机微信，扫描二维码</div>
            <div>进入新疆人民医院互联网医院小程序</div>
            <div>线上复诊、慢病开方、网约护理</div>
          </div>
        </div>
      </el-dialog>
      <!-- 底部 -->
      <Footer></Footer>
    </div>
    <!-- 移动端 -->
    <div v-else class="m-indexView">
      <!-- banner图 -->
      <div class="m-carouselImgs">
        <el-carousel>
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <!-- <img src="../assets/images/222223.png" alt /> -->
            <img
              v-if="item.attributes"
              :src="imgUrl + item.attributes.image.data.attributes.url"
              alt
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 搜索框 -->
      <div class="searchValue">
        <el-row type="flex" class="row-bg" justify="center">
          <el-col :span="5" class="selectSeaView">
            <el-select
              v-model="selectValue"
              placeholder="请选择"
              @change="selectChange"
            >
              <el-option
                v-for="item in searchOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="13">
            <div class="searchIpt">
              <el-input
                placeholder="请输入内容"
                v-model="searchValue"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="searchBtn">
              <el-button
                type="primary"
                icon="el-icon-search"
                @click="toSearchPageMob"
                >立即搜索</el-button
              >
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 新闻中心 -->
      <div class="m-newView">
        <div class="m-nTitle">
          <span></span>
          <span>{{ newObj.title }}</span>
        </div>
        <div class="opTabsView">
          <!-- 新闻banner -->
          <div class="newsSwiper">
            <el-carousel v-if="newsSwiperList.length > 0">
              <el-carousel-item
                class="carouselView"
                v-for="(item, index) in newsSwiperList"
                :key="index"
                @click.native="detailInfoClickMob(item, 'news')"
              >
                <img
                  class="newsImg"
                  v-if="item.attributes"
                  :src="
                    imgUrl + item.attributes.featureImage.data.attributes.url
                  "
                  alt
                />
                <div class="newsTitle">
                  <div class="text" v-if="item.attributes">
                    {{ item.attributes.title }}
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
          <el-tabs
            v-model="activeTabName"
            type="border-card"
            @tab-click="tabClickNew"
            v-if="newObj.items"
          >
            <el-tab-pane
              v-for="(item, index) in newObj.items.data"
              :key="index"
              :value="item.attributes.title"
              :name="item.attributes.title"
              :label="item.attributes.title"
            >
              <!-- 内容 -->
              <div
                class="m-healthMajListView"
                v-if="newsDetailListMob.length > 0"
              >
                <div
                  class="m-healthDetailList"
                  v-for="(item, index) in newsDetailListMob"
                  :key="index"
                  @click="detailInfoClickMob(item, 'news')"
                >
                  <div class="m-healthImg">
                    <img src="../../src/assets/icons/yq.png" alt="" />
                  </div>
                  <div class="m-healthText">
                    <div class="m-healthTextTitle">
                      {{ item.attributes.articleTitle }}
                    </div>
                  </div>
                  <div class="m-healthTimer">
                    {{ item.attributes.updatedAt.slice(0, 10) }}
                  </div>
                </div>
                <div class="m-more-btn">
                  <el-button
                    @click="mobNewOpt(item, 'news')"
                    type="primary"
                    icon="el-icon-plus"
                    >更多</el-button
                  >
                </div>
              </div>
              <div class="noDataMob" v-else>暂无数据</div>
            </el-tab-pane>
          </el-tabs>
        </div>
        <!-- <div class="m-nListView" v-if="newObj.items">
          <div
            class="m-nList"
            v-for="(item, index) in newObj.items.data"
            :key="index"
            @click="mobNewOpt(item, 'news')"
          >
            {{ item.attributes.title }}
          </div>
        </div> -->
      </div>
      <!-- 科室中心 -->
      <div class="m-deptView">
        <div class="m-dTitle">
          <span></span>
          <span>科室中心</span>
        </div>
        <div class="m-deptMajListView">
          <div class="m-deptMajList" @click="mobNewOpt(deptList[0], 'dept')">
            <img src="../assets/images/h_dep_zd.png" alt="" />
            <div class="m-deptMajTitleView">
              <div class="m-deptMajTitle">
                {{ deptList[0].name.slice(0, 3) }}
              </div>
              <div class="m-deptMajTitle">
                {{ deptList[0].name.slice(3, 7) }}
              </div>
            </div>
          </div>
          <div class="m-deptMajList" @click="mobNewOpt(deptList[1], 'dept')">
            <img src="../assets/images/h_dep_nk.png" alt="" />
            <div class="m-deptMajTitle">{{ deptList[1].name }}</div>
          </div>
          <div class="m-deptMajList" @click="mobNewOpt(deptList[2], 'dept')">
            <img src="../assets/images/h_dep_wk.png" alt="" />
            <div class="m-deptMajTitle">{{ deptList[2].name }}</div>
          </div>
          <div class="m-deptMajList" @click="mobNewOpt(deptList[3], 'dept')">
            <img src="../assets/images/h_dep_yj.png" alt="" />
            <div class="m-deptMajTitle">{{ deptList[3].name }}</div>
          </div>
        </div>
      </div>
      <!-- 专家介绍 -->
      <div class="m-exportPropView">
        <div class="m-eTitle">
          <span></span>
          <span>专家介绍</span>
        </div>
        <div class="m-expertView">
          <div class="m-expertTiView">
            <div
              :class="expTabActive2 ? 'm-expertTiListActive' : 'm-expertTiList'"
              @click="expTabActiveFun2"
            >
              <img
                :src="
                  expTabActive2
                    ? require('../assets/images/h_zj_xkA.png')
                    : require('../assets/images/h_zj_xk.png')
                "
                alt=""
              /><span>学术带头人</span>
            </div>
            <div
              :class="expTabActive1 ? 'm-expertTiListActive' : 'm-expertTiList'"
              @click="expTabActiveFun1"
            >
              <img
                :src="
                  expTabActive1
                    ? require('../assets/images/h_zj_xsA.png')
                    : require('../assets/images/h_zj_xs.png')
                "
                alt=""
              /><span>学科带头人</span>
            </div>
            <div
              :class="expTabActive3 ? 'm-expertTiListActive' : 'm-expertTiList'"
              @click="expTabActiveFun3"
            >
              <img
                :src="
                  expTabActive3
                    ? require('../assets/images/h_zj_lc.png')
                    : require('../assets/images/h_zj_lc.png')
                "
                alt=""
              /><span>临床专家</span>
            </div>
          </div>
          <div class="m-expertSwiView">
            <el-carousel>
              <el-carousel-item
                class="m-carouselView"
                v-for="(item, index) in zjjsSwiperList"
                :key="index"
              >
                <div class="m-expertListView">
                  <div
                    class="m-expertList"
                    v-for="(ite, ind) in item"
                    :key="ind"
                    @click="mobexpertDetailClick(ite)"
                  >
                    <div class="m-expertListLef">
                      <img
                        class="m-expertImg"
                        v-if="ite.attributes.photo.data"
                        :src="imgUrl + ite.attributes.photo.data.attributes.url"
                        alt=""
                      />
                      <div v-else class="imgNoMob">暂无照片</div>
                    </div>
                    <div class="m-expertListRig">
                      <div class="m-expertName">
                        {{ ite.attributes.doctorName }}
                      </div>
                      <div class="m-expertZc">
                        {{ ite.attributes.doctorTitle }}
                      </div>
                    </div>
                  </div>
                  <!-- <div class="m-expertList" @click="expertDetailClick">
                    <div class="m-expertListLef">
                      <img
                        class="m-expertImg"
                        src="../assets/images/h_zj_img2.jpg"
                        alt=""
                      />
                    </div>
                    <div class="m-expertListRig">
                      <div class="m-expertName">杨毅宁</div>
                      <div class="m-expertZc">
                        医学博士、心血管内科专业、主任医师
                      </div>
                    </div>
                  </div>
                  <div class="m-expertList" @click="expertDetailClick">
                    <div class="m-expertListLef">
                      <img
                        class="m-expertImg"
                        src="../assets/images/h_zj_img1.jpg"
                        alt=""
                      />
                    </div>
                    <div class="m-expertListRig">
                      <div class="m-expertName">李南方</div>
                      <div class="m-expertZc">
                        主任医师、教授、博/硕士研究生导师
                      </div>
                    </div>
                  </div>
                  <div class="m-expertList" @click="expertDetailClick">
                    <div class="m-expertListLef">
                      <img
                        class="m-expertImg"
                        src="../assets/images/h_zj_img.jpg"
                        alt=""
                      />
                    </div>
                    <div class="m-expertListRig">
                      <div class="m-expertName">戈小虎</div>
                      <div class="m-expertZc">
                        血管外科专业、主任医师、教授、博/硕士研究生导师、国务院特殊津贴专家
                      </div>
                    </div>
                  </div> -->
                </div>
              </el-carousel-item>
            </el-carousel>
            <!-- <div class="m-more-btn">
              <el-button
                @click="mobDeptOpt()"
                type="primary"
                icon="el-icon-plus"
                >更多</el-button
              >
            </div> -->
          </div>
        </div>
        <!-- <div class="m-expMajListView">
          <div class="m-expMajList" @click="mobNewOpt('学术带头人', 'export')">学术带头人</div>
          <div class="m-expMajList" @click="mobNewOpt('学科带头人', 'export')">学科带头人</div>
          <div class="m-expMajList" @click="mobNewOpt('临床专家', 'export')">临床专家</div>
        </div> -->
      </div>
      <!-- 功能介绍 -->
      <div class="m-funPropView">
        <div class="m-fList" @click="funClick('zy')">
          <div class="m-f-t-d">
            <div class="m-f-t">预约挂号</div>
            <div class="m-f-d">手机挂号 方便快捷</div>
          </div>
          <img class="imgF" src="../assets/images/home_yygh.png" alt="" />
        </div>
        <div class="m-fList" @click="funClick('hlw')">
          <div class="m-f-t-d">
            <div class="m-f-t">互联网医院</div>
            <div class="m-f-d">复诊患者 问诊开药</div>
          </div>
          <img class="imgF" src="../assets/images/home_hlwyy.png" alt="" />
        </div>
        <div class="m-fList">
          <div class="m-f-t-d">
            <div class="m-f-t">院内分区</div>
            <div class="m-f-d">清晰导览 真诚服务</div>
          </div>
          <img class="imgF" src="../assets/images/home_ynfq.png" alt="" />
        </div>
        <div class="m-fList" @click="funClick('dh')">
          <div class="m-f-t-d">
            <div class="m-f-t">来院导航</div>
            <div class="m-f-d">精准指引 轻松就医</div>
          </div>
          <img class="imgF" src="../assets/images/home_dh.png" alt="" />
        </div>
      </div>
      <!-- 健康宣教 -->
      <div class="m-healthPropView">
        <div class="m-hTitle">
          <span></span>
          <span>健康宣教</span>
        </div>
        <div class="m-healthMajListView">
          <div
            class="m-healthDetailList"
            v-for="(item, index) in healthListAll"
            :key="index"
            @click="detailInfoClickMob(item, 'news')"
          >
            <div class="m-healthImg">
              <img src="../../src/assets/icons/yq.png" alt="" />
            </div>
            <div class="m-healthText">
              <div class="m-healthTextTitle">
                {{ item.attributes.articleTitle }}
              </div>
            </div>
            <div class="m-healthTimer">
              {{ item.attributes.updatedAt.slice(0, 10) }}
            </div>
          </div>
          <div class="m-more-btn">
            <el-button
              @click="mobNewOpt(item, 'news')"
              type="primary"
              icon="el-icon-plus"
              >更多</el-button
            >
          </div>
        </div>
      </div>
      <!-- 弹框 -->
      <el-dialog
        title=""
        :visible.sync="dialogVisible"
        width="90%"
        :before-close="handleClose"
      >
        <div class="eqCodeDiv">
          <div class="eqImg">
            <img v-if="wxzyShow" src="../assets/icons/wxzy.png" alt />
            <img v-else src="../assets/icons/hlweq.png" alt />
          </div>
          <div class="eqtext" v-if="wxzyShow">
            <div>使用手机微信，扫描二维码</div>
            <div>进入新疆人民医院掌上医院小程序</div>
            <div>手机挂号、缴费退费、查看报告</div>
          </div>
          <div class="eqtext" v-else>
            <div>使用手机微信，扫描二维码</div>
            <div>进入新疆人民医院互联网医院小程序</div>
            <div>线上复诊、慢病开方、网约护理</div>
          </div>
        </div>
      </el-dialog>
      <!-- 底部 -->
      <FooterMob></FooterMob>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { config } from "@/api/config";
import Footer from "@/components/footer.vue";
import FooterMob from "@/components/footerMob.vue";
import { IsPC } from "@/utils/agentFlag";
export default {
  name: "homePage",
  components: {
    Footer,
    FooterMob,
  },
  data() {
    return {
      bannerList: [],
      isPcShow: true,
      searchValue: "",
      newObj: {},
      newList: [],
      newsSwiperList: [],
      newsDetailList: [],
      newsDetailListMob: [],
      newsActiveList: [],
      newsSwiActiveList: [],
      depNkShow: false,
      depWkShow: false,
      depYjShow: false,
      depZdShow: true,
      deptList: [
        {
          name: "国家临床重点专科",
          id: "0",
        },
        {
          name: "内科科室",
          id: "1",
        },
        {
          name: "外科科室",
          id: "2",
        },
        {
          name: "医技科室",
          id: "3",
        },
      ],
      depZDList: [],
      depNKList: [],
      depWKList: [],
      depYJList: [],
      deptObj: {},
      deptDetailList: [],
      expTabActive1: false,
      expTabActive2: true,
      expTabActive3: false,
      zjjsSwiperList: [],
      expertList: [],
      healthListAll: [],
      healthList: [],
      activeTabName: "",
      dialogVisible: false,
      wxzyShow: true,
      searchOptions: [
        {
          label: "新闻",
          value: "article",
        },
        {
          label: "医生",
          value: "doctor",
        },
        {
          label: "科室",
          value: "department",
        },
      ],
      selectValue: "article",
    };
  },
  created() {
    this.imgUrl = config().imgserve;
  },
  mounted() {
    if (IsPC()) {
      this.isPcShow = true;
    } else {
      this.isPcShow = false;
    }
    this.getBanner();
    this.getNewsList("医院动态");
    this.getJKNewsList("健康科普");
    this.getDoctors("学术带头人");
    this.getMenuList();
    // this.getDeptList()
    this.getDeptList(this.deptList[0].name);
    this.getDeptList(this.deptList[1].name);
    this.getDeptList(this.deptList[2].name);
    this.getDeptList(this.deptList[3].name);
  },
  methods: {
    // 按规定长度截取数组
    grouping(arr, len) {
      let col = Math.ceil(arr.length / len);
      let data = [];
      let start = 0;
      while (start < col) {
        let temp = arr.slice(start * len, (start + 1) * len);
        data[start] = temp;
        start++;
      }
      return data;
    },
    selectChange(e) {
      this.searchValue = "";
      // console.log("看看选择", e, this.selectValue);
    },
    // 获取banner
    getBanner() {
      axios
        .get(
          config().urlServe +
            "/api/banners?populate=*&sort=sortNo:desc&pagination[pageSize]=100&filters[location][$eq]=HOME"
        )
        .then((res) => {
          if (res.status === 200) {
            console.log("banner", res.data);
            this.$nextTick(() => {
              this.bannerList = res.data.data;
            });
          }
        })
        .catch((err) => {
          // alert(err)
        });
    },
    // 搜索PC
    toSearchPage() {
      this.$router.push({
        path: "/searchPage",
        query: {
          title: "搜索",
          value: this.selectValue,
          keyword: this.searchValue,
        },
      });
    },
    // 搜索 移动端
    toSearchPageMob() {
      this.$router.push({
        path: "/searchPageMob",
        query: {
          title: "搜索",
          value: this.selectValue,
          keyword: this.searchValue,
        },
      });
    },
    // 功能弹框
    funClick(e) {
      // console.log("eeeee", e, this.dialogVisible);
      if (e === "zy") {
        this.dialogVisible = true;
        this.wxzyShow = true;
      } else if (e === "hlw") {
        this.dialogVisible = true;
        this.wxzyShow = false;
      } else {
        // 腾讯地图
        window.location.href =
          "https://apis.map.qq.com/uri/v1/marker?marker=coord:43.784047,87.614524;title:新疆维吾尔自治区人民医院;addr:新疆乌鲁木齐市天池路91号&referer=myapp";
        // 百度地图
        // window.location.href ='http://api.map.baidu.com/marker?location=43.784047,87.614524&&title=新疆维吾尔自治区人民医院&content=新疆乌鲁木齐市天池路91号&output=html&src=webapp.baidu.openAPIdemo';
        // 高德地图 暂时不行
        // window.location.href ='URL: //uri.amap.com/marker?position=87.614524,43.784047&name=新疆维吾尔自治区人民医院&src=mypage&coordinate=gaode&callnative=0';
      }
    },
    // 关闭科室预约
    handleClose() {
      this.dialogVisible = false;
    },
    // 新闻详情
    detailInfoClick(e, type) {
      // 判断是否跳转外部链接
      if (e.attributes.link) {
        window.location.href = e.attributes.link
      } else {
        this.$router.push({
          path: "/deptDetailInfo",
          query: {
            list: "详情",
            type: type,
            id: e.id,
          },
        });
      }
    },
    // 新闻详情移动
    detailInfoClickMob(e, type) {
      // 判断是否跳转外部链接
      if (e.attributes.link) {
        window.location.href = e.attributes.link
      } else {
        this.$router.push({
          path: "/deptDetailInfoMob",
          query: {
            list: "详情",
            type: type,
            id: e.id,
          },
        });
      }
    },
    // 获取菜单
    getMenuList() {
      axios
        .get(config().urlServe + '/api/menus?populate=*&nested=""')
        .then((res) => {
          if (res.status === 200) {
            this.newObj = res.data.data[1].attributes;
            this.activeTabName = this.newObj.items.data[0].attributes.title;
            // console.log("新闻中心", this.newObj.items.data[0]);
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    // 科室详情
    deptdetailClick(e) {
      this.$router.push({
        path: "/deptDetail",
        query: {
          list: e.attributes.name,
          id: e.id,
        },
      });
    },
    // 科室类别详情
    // getDeptList() {
    //     // this.deptObj = []
    //     axios
    //         .get(
    //             config().urlServe +
    //             "/api/department-types?populate[smallIcon]=true&populate[largeIcon]=true&populate[departments][populate][3]=photo&sort[0]=sortNo:desc&pagination[pageSize]=3"
    //         )
    //         .then(res => {
    //             console.log('科室类别', res)
    //             if (res.status === 200) {
    //                 this.deptList = res.data.data;
    //                 if (this.deptList.length !== 0) {
    //                     this.deptObj = this.deptList[0].attributes;
    //                 }
    //             }
    //         })
    //         .catch(err => {
    //             alert(err);
    //         });
    // },
    // 科室tabs
    depthandleClick(tab) {
      // this.deptDetailList = []
      this.deptObj = {};
      this.getDeptList(tab.name);
      //   this.deptList.forEach((item, index) => {
      //     if (tab.name === item.attributes.name) {
      //       this.deptObj = item.attributes;
      //     }
      //   });
    },
    // 各科室数据
    getDeptList(val) {
      this.deptDetailList = [];
      this.deptobj = {};
      let api = "";
      api =
        "/api/department-types?pagination[pageSize]=1000&pagination[page]=1&populate=*&sort=sortNo:desc&filters[name][$in][0]=" +
        val +
        "&embed=DepartmentType";
      axios
        .get(config().urlServe + api)
        .then((res) => {
          // console.log("科室数据", val, res.data.data[0]);
          if (res.status === 200) {
            if (res.data.data[0]) {
              if (val === "国家临床重点专科") {
                // this.deptobj = res.data.data[0].attributes;
                this.deptDetailList =
                  res.data.data[0].attributes.departments.data;
                this.depZDList = res.data.data[0].attributes.departments.data;
              }
              if (val === "内科科室") {
                this.depNKList = res.data.data[0].attributes.departments.data;
              }
              if (val === "外科科室") {
                this.depWKList = res.data.data[0].attributes.departments.data;
              }
              if (val === "医技科室") {
                this.depYJList = res.data.data[0].attributes.departments.data;
              }
            }
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    // 新闻
    getNewsList(val) {
      // console.log("99988888", val, val.length);
      this.newsDetailList = [];
      this.imgList = [];
      axios
        .get(
          config().urlServe +
            "/api/articles?filters[article_tags][articleTagName][$eq]=" +
            val +
            "&populate=*&sort[0]=sortNo:desc&sort[1]=updatedAt:desc"
        )
        .then((res) => {
          if (res.status === 200) {
            let list = res.data.data;
            let newsSwiperList = [];
            list.forEach((item) => {
              let obj = {};
              const tempDivElement = document.createElement("div");
              tempDivElement.innerHTML = item.attributes.content;
              const planText =
                tempDivElement.textContent || tempDivElement.innerText || "";
              item.attributes.contStr = planText.substring(0, 100);
              item.attributes.isActive = false;
              item.attributes.timer = item.attributes.updatedAt.slice(0, 7);
              item.attributes.monthr = item.attributes.updatedAt.slice(5, 7);
              item.attributes.dayr = item.attributes.updatedAt.slice(8, 10);
              if (item.attributes.featureImage.data) {
                obj = item;
                newsSwiperList.push(obj);
              }
            });
            if (newsSwiperList.length < 2) {
              this.newsSwiperList = newsSwiperList;
            } else {
              this.newsSwiperList = newsSwiperList.slice(0, 1);
            }
            let arr = list.slice(0, 2);
            this.newsDetailList = arr;
            this.newsDetailListMob = list.slice(0, 7);
            // console.log("新闻newsDetailList", this.newsDetailList);
            if (val === "医院动态") {
              this.newsActiveList = this.newsDetailList;
              this.newsSwiActiveList = this.newsSwiperList;
            }
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    // 招标
    getZbNewsList(val) {
      this.newsDetailList = [];
      this.newsSwiperList = [];
      this.imgList = [];
      axios
        .get(
          config().urlServe +
            "/api/articles?filters[article_tags][articleTagName][$startsWith]=" +
            val +
            "&populate=*&sort[0]=sortNo:desc&sort[1]=updatedAt:desc"
        )
        .then((res) => {
          if (res.status === 200) {
            let list = res.data.data;
            let newsSwiperList = [];
            list.forEach((item) => {
              let obj = {};
              const tempDivElement = document.createElement("div");
              tempDivElement.innerHTML = item.attributes.content;
              const planText =
                tempDivElement.textContent || tempDivElement.innerText || "";
              item.attributes.contStr = planText.substring(0, 100);
              item.attributes.isActive = false;
              item.attributes.timer = item.attributes.updatedAt.slice(0, 7);
              item.attributes.monthr = item.attributes.updatedAt.slice(5, 7);
              item.attributes.dayr = item.attributes.updatedAt.slice(8, 10);
              if (item.attributes.featureImage.data) {
                obj = item;
                newsSwiperList.push(obj);
              }
            });
            if (newsSwiperList.length < 2) {
              this.newsSwiperList = newsSwiperList;
            } else {
              this.newsSwiperList = newsSwiperList.slice(0, 1);
            }
            let arr = list.slice(0, 2);
            // this.newsDetailList = list;
            this.newsDetailList = arr;
            this.newsDetailListMob = list.slice(0, 7);
            // console.log("新闻newsDetailList", this.newsDetailList, this.newsSwiperList);
            if (val === "医院动态") {
              this.newsActiveList = this.newsDetailList;
              this.newsSwiActiveList = this.newsSwiperList;
            }
            // console.log(
            //   "新闻newsDetailList",
            //   res.data.meta.pagination.total,
            //   this.newsDetailList,
            //   this.newsSwiperList
            // );
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    // 健康宣教
    getJKNewsList(val) {
      this.newsDetailList = [];
      this.imgList = [];
      axios
        .get(
          config().urlServe +
            "/api/articles?filters[article_tags][articleTagName][$eq]=" +
            val +
            "&populate=*&sort[0]=sortNo:desc&sort[1]=updatedAt:desc"
        )
        .then((res) => {
          if (res.status === 200) {
            let list = res.data.data;
            list.forEach((item) => {
              
              let obj = {};
              const tempDivElement = document.createElement("div");
              tempDivElement.innerHTML = item.attributes.content;
              const planText =
                tempDivElement.textContent || tempDivElement.innerText || "";
              item.attributes.contStr = planText.substring(0, 100);
              item.attributes.isActive = false;
              item.attributes.timer = item.attributes.updatedAt.slice(0, 7);
              item.attributes.monthr = item.attributes.updatedAt.slice(5, 7);
              item.attributes.dayr = item.attributes.updatedAt.slice(8, 10);
            });
            let arr = list.slice(0, 7);
            this.$nextTick(() => {
              this.healthListAll = list;
              if (arr.length > 0 && arr.length === 1) {
                this.healthList = [];
              }
              if (arr.length > 1) {
                arr.shift();
                this.healthList = arr;
              }
              // console.log(
              //   "健康宣教healthList",
              //   this.healthListAll,
              //   this.healthList
              // );
            });
            
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    // 专家
    getDoctors(val) {
      this.expertList = [];
      axios
        .get(
          config().urlServe +
            "/api/doctors?pagination[pageSize]=1000&pagination[page]=1&populate=*&sort=sortNo:desc&filters[departments][name][$eq][0]=" +
            val +
            "&filters[departments][name][$eq][1]="
        )
        .then((res) => {
          // console.log("专家", res);
          if (res.status === 200) {
            // this.zjjsSwiperList = [];

            // this.expertList = res.data.data.slice(0, 3);
            this.zjjsSwiperList = [];
            this.expertList = [];
            this.expertList = res.data.data;
            this.zjjsSwiperList = this.grouping(res.data.data, 3);
            // console.log("this.expertList", this.zjjsSwiperList);
            // this.zjjsSwiperList = this.grouping(res.data.data, 3);
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    // 专家详情
    expertDetailClick(e) {
      this.$router.push({
        path: "/expertDetail",
        query: {
          list: e.attributes.doctorName,
          id: e.id,
        },
      });
    },
    mobexpertDetailClick(e) {
      this.$router.push({
        path: "/expertDetailMob",
        query: {
          list: e.attributes.doctorName,
          id: e.id,
        },
      });
    },
    // 鼠标移入
    mouseenterMenus(e) {
      e.currentTarget.className = "newMenusActive";

      // this.getNewsList()
    },
    mouseleaveMenus(e) {
      // console.log("移出");
      // e.currentTarget.className = 'newMenus';
      this.newsDetailList = this.newsActiveList;
      this.newsSwiperList = this.newsSwiActiveList;
    },
    mouseenterList(e) {
      // console.log("进入新闻列表", e.target, e.target.innerHTML.trim().length);
      e.currentTarget.className = "newListActive";
      if (e.target.innerHTML.includes("招标")) {
        this.getZbNewsList(e.target.innerHTML.trim());
      } else {
        this.getNewsList(e.target.innerHTML.trim());
      }
      // this.getNewsList(e.target.innerHTML.trim());
    },
    mouseleaveList(e) {
      e.currentTarget.className = "newListNormal";
    },
    // 科室
    mouseleaveNK() {
      this.depNkShow = false;
    },
    mouseenterNK() {
      this.depNkShow = true;
      this.depWkShow = false;
      this.depYjShow = false;
      this.depZdShow = false;
      this.deptDetailList = this.depNKList;
      // this.getDeptList(this.deptList[1].name);
    },
    mouseleaveWK() {
      this.depWkShow = false;
    },
    mouseenterWK() {
      this.depWkShow = true;
      this.depNkShow = false;
      this.depYjShow = false;
      this.depZdShow = false;
      this.deptDetailList = this.depWKList;
      // this.getDeptList(this.deptList[2].name);
    },
    mouseleaveYJ() {
      this.depYjShow = false;
    },
    mouseenterYJ() {
      this.depYjShow = true;
      this.depNkShow = false;
      this.depWkShow = false;
      this.depZdShow = false;
      this.deptDetailList = this.depYJList;
      // this.getDeptList(this.deptList[3].name);
    },
    mouseleaveZD() {
      this.depZdShow = false;
    },
    mouseenterZD() {
      this.depZdShow = true;
      this.depNkShow = false;
      this.depWkShow = false;
      this.depYjShow = false;
      this.deptDetailList = this.depZDList;
      // this.getDeptList(this.deptList[0].name);
    },
    mouseleaveDep() {
      this.depNkShow = false;
      this.depWkShow = false;
      this.depYjShow = false;
      this.depZdShow = true;
      this.deptDetailList = this.depZDList;
      // this.getDeptList(this.deptList[0].name);
    },
    // 专家tab
    expTabActiveFun1() {
      this.expTabActive1 = true;
      this.expTabActive2 = false;
      this.expTabActive3 = false;
      this.getDoctors("学科带头人");
    },
    expTabActiveFun2() {
      this.expTabActive2 = true;
      this.expTabActive1 = false;
      this.expTabActive3 = false;
      this.getDoctors("学术带头人");
    },
    expTabActiveFun3() {
      this.expTabActive3 = true;
      this.expTabActive1 = false;
      this.expTabActive2 = false;
      this.getDoctors("临床专家");
    },
    // 移动端
    mobNewOpt(e, type) {
      let title = "";
      if (type === "news") {
        title = e.attributes.title;
      }
      if (type === "dept") {
        title = e.name;
      }
      if (type === "export") {
        title = e;
      }
      this.$router.push({
        path: "/optionListMob",
        query: {
          title: title,
          list: title,
          type: type,
          active: 3,
        },
      });
    },
    mobDeptOpt() {
      let title = "";
      if (this.expTabActive1) {
        title = "学科带头人";
      }
      if (this.expTabActive2) {
        title = "学术带头人";
      }
      if (this.expTabActive3) {
        title = "临床专家";
      }
      this.$router.push({
        path: "/optionListMob",
        query: {
          title: title,
          list: title,
          type: "news",
          active: 3,
        },
      });
    },
    // mobNewOpt(e, type) {
    //   let title = "";
    //   if (type === "news") {
    //     title = e.attributes.title;
    //   }
    //   if (type === "dept") {
    //     title = e.name;
    //   }
    //   if (type === "export") {
    //     title = e;
    //   }
    //   this.$router.push({
    //     path: "/optionListMob",
    //     query: {
    //       title: title,
    //       list: title,
    //       type: type,
    //       active: 3
    //     },
    //   });
    // },
    tabClickNew(e) {
      console.log("点了", e.$attrs.value);
      if (e.$attrs.value.includes("招标")) {
        this.getZbNewsList(e.$attrs.value);
      } else {
        this.getNewsList(e.$attrs.value);
      }
      // this.getNewsList(e.$attrs.value);
    },
  },
};
</script>
<style>
.el-dialog__title {
  font-size: 6px !important;
}
.el-dialog__header {
  padding: 10px 10px 0px !important;
}
.el-dialog__body {
  padding: 10px !important;
}
.el-dialog__headerbtn {
  font-size: 7px !important;
}
.v-modal {
  z-index: 0 !important;
}
.el-select-dropdown__item {
  padding: 0 5px !important;
  font-size: 5px !important;
  height: 8px !important;
  line-height: 8px !important;
  border-radius: 2px !important;
  text-align: center !important;
}
.el-select-dropdown__list {
  padding: 5px 0px !important;
}
.el-select-dropdown {
  min-width: 35px !important;
  border: 0.1px solid #eee !important;
}
.el-popper[x-placement^="bottom"] {
  margin-top: 2px !important;
}
.el-select-dropdown__item.selected {
  color: #089291 !important;
}
</style>

<style lang="less" scoped>
.eqCodeDiv {
  .eqImg {
    display: flex;
    width: 100%;
    font-weight: normal;
    justify-content: space-around;
  }
  img {
    width: 45%;
  }
  .eqtext {
    font-size: 6px;
    color: #999;
    margin-top: 5px;
    width: 100%;
    div {
      width: 100%;
      text-align: center;
    }
  }
}
.selectSeaView {
  position: relative;
  top: 5px;
  /deep/ .el-input--suffix .el-input__inner {
    padding-right: 0px !important;
  }
  /deep/ .el-input__inner {
    padding: 0px 5px !important;
    height: 14px !important;
    line-height: 14px !important;
    border-radius: 2px !important;
    border: 0.1px solid #eee !important;
    font-size: 6px !important;
    color: #089291 !important;
  }
  /deep/ .el-select .el-input .el-select__caret {
    line-height: 8px !important;
    font-size: 8px !important;
  }
  /deep/ .el-input__icon {
    width: 10px !important;
  }
}

// pc
.indexContainer {
  position: relative;
  margin-top: 0px;
  box-sizing: border-box;
  position: relative;
  z-index: 1;

  .listEmpty {
    width: 100%;
    text-align: center;
    // padding-top: 20px;
    img {
      width: 40px !important;
      margin-top: 20px !important;
    }
  }
  .carouselImgs {
    width: 100%;

    /deep/ .el-carousel__container {
      height: 150px !important;
    }

    img {
      width: 100%;
    }
  }

  .searchValue {
    width: 70%;
    margin: 10px auto;
    border: 0.1px solid #eee;
    border-bottom: 1px solid #089291;

    .searchIpt {
      padding: 10px;

      /deep/ .el-input {
        font-size: 0;
      }

      /deep/ .el-input__inner {
        height: 15px !important;
        font-size: 5px !important;
        border: 0.1px solid #eee;
        border-radius: 2px;
      }
    }

    .searchBtn {
      padding: 10px 0px;

      .el-button {
        height: 15px !important;
        line-height: 15px !important;
        font-size: 5px !important;
        padding: 0px 4px !important;
        border-radius: 2px;
      }

      /deep/ .el-button [class*="el-icon-"] + span {
        margin-left: 0px !important;
      }

      .el-button--primary {
        color: #fff;
        background-color: #089291;
        border-color: #089291;
      }
    }
  }

  .newView {
    width: 100%;
    background: #dff1f5;
    margin-top: 30px;
    margin-bottom: 30px;

    .newMenusView {
      width: 64%;
      margin: 0 auto;
      position: relative;
      display: flex;

      // 鼠标移出 默认显示
      .newMenus {
        width: 40%;
        background: #089c98;
        background-image: url("../assets/images/h_new_bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: 100% 100%;

        .newTitle {
          margin-top: 10px;
          margin-left: 15px;

          .newTitleCH {
            font-size: 9px;
            font-weight: bold;
            letter-spacing: 1px;
            color: #fff;
          }

          .newTitleEG {
            font-size: 6px;
            color: #e7e7e7;
          }
        }

        .newListView {
          margin-top: 7px;
          margin-bottom: 15px;
          width: 70%;

          .newList {
            padding: 5px 35px;
            color: #ffffff;
            font-size: 5px;
            margin-top: 4px;
            width: 40px;
          }

          .newList:first-child {
            padding: 5px 35px;
            color: #089291;
            background: #ffffff;
            font-size: 5px;
          }

          .newList:hover {
            padding: 5px 35px;
            color: #089291;
            background: #ffffff;
            font-size: 5px;
          }

          .newListActive {
            padding: 5px 35px;
            color: #089291;
            background: #ffffff;
            font-size: 5px;
            margin-top: 4px;
            width: 40px;
          }

          .newListNormal {
            padding: 5px 35px;
            color: #ffffff;
            font-size: 5px;
            margin-top: 4px;
            width: 40px;
          }
        }
      }

      // 鼠标移入
      .newMenusActive {
        width: 40%;
        background: #089c98;
        background-image: url("../assets/images/h_new_bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: 100% 100%;

        .newTitle {
          margin-top: 10px;
          margin-left: 15px;

          .newTitleCH {
            font-size: 9px;
            font-weight: bold;
            letter-spacing: 1px;
            color: #fff;
          }

          .newTitleEG {
            font-size: 6px;
            color: #e7e7e7;
          }
        }

        .newListView {
          margin-top: 7px;
          margin-bottom: 15px;
          width: 70%;

          .newList {
            padding: 5px 35px;
            color: #ffffff;
            font-size: 5px;
            margin-top: 4px;
            width: 40px;
          }

          // .newList:first-child {
          //     padding: 5px 35px;
          //     color: #089291;
          //     background: #ffffff;
          //     font-size: 5px;
          // }

          .newList:hover {
            padding: 5px 35px;
            color: #089291;
            background: #ffffff;
            font-size: 5px;
          }

          .newListActive {
            padding: 5px 35px;
            color: #089291;
            background: #ffffff;
            font-size: 5px;
            margin-top: 4px;
            width: 40px;
          }

          .newListNormal {
            padding: 5px 35px;
            color: #ffffff;
            font-size: 5px;
            margin-top: 4px;
            width: 40px;
          }
        }
      }

      .newdetailView {
        width: 67%;
        height: 190px;
        font-size: 5px;
        text-align: center;
        line-height: 100%;
        margin-top: -15px;
        margin-left: -20px;
      }

      .newsSwiper {
        // border: 0.1px solid #3AB57F;
        box-shadow: 0px 0px 2px 0.3px #089291;
        background: #fff;
        margin-bottom: 1px;

        .newsImg {
          width: 100%;
          height: 100%;
          border-radius: 0px;
        }

        .newsTitle {
          position: relative;
          top: -20px;
          height: 20px;
          width: 100%;
          line-height: 20px;
          color: rgba(255, 255, 255, 1);
          background-color: rgba(77, 75, 75, 0.5);
          font-size: 6px;
          font-weight: bold;

          .text {
            width: 67%;
            padding-left: 2px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        /deep/ .el-carousel__container {
          height: 115px !important;
          // background: red;
        }

        img {
          width: 100%;
        }

        /deep/ .el-carousel__indicators--horizontal {
          width: calc(100% - 10px);
          text-align: right;
          border-radius: 0 0 10px 10px;
          padding-right: 10px;
          height: 20px;
          line-height: 20px;
        }

        /deep/ .el-carousel__indicator--horizontal {
          padding: 0;
          padding-right: 2px;
        }

        /deep/ .el-carousel__button {
          width: 4px;
          height: 4px;
          border-radius: 50%;
        }
      }

      .newsDetailListValue {
        width: 100%;
        height: 65px;
        background: #fff;

        .newsDetailList {
          width: calc(100% - 20px);
          padding: 5px 10px 0px;
          text-align: left;
          display: flex;
          justify-content: space-between;

          .newTimer {
            width: 28px;
            height: 18px;
            border: 0.1px solid #eee;
            border-radius: 1px;
            box-sizing: border-box;
            display: flex;

            img {
              height: 15px;
              position: relative;
              left: 1px;
              top: 1.5px;
            }

            .newTimerD {
              margin-top: 3px;
              color: #089291;

              span {
                display: block;
                margin-left: 1px;
                font-size: 6px;
                font-weight: bold;
              }

              span:last-child {
                font-size: 4px;
                font-weight: normal;
                margin-top: 2px;
              }
            }
          }

          .newText {
            width: calc(100% - 32px);
            padding-bottom: 5px;
            border-bottom: 0.1px solid #eee;

            .newTextTitle {
              color: #333;
              font-size: 5px;
              font-weight: bold;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              // background: #049d98;
              height: 8px;
              line-height: 8px;
            }

            .newTextDesc {
              font-size: 4px;
              height: 12px;
              line-height: 6px;
              margin-top: 2px;
              color: #888;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              // background: #3c9d04;
            }
          }
        }
      }
    }
  }

  .funDepView {
    width: 100%;
    background: #dff1f5;
    margin-top: 30px;
    margin-bottom: 30px;

    .funView {
      width: 100%;
      background: #1e4743;
      padding-bottom: 10px;

      .funOneView {
        width: 70%;
        margin: 0 auto;
        display: flex;

        // background: rgb(151, 145, 145);
        .funListView {
          width: 50%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 10px 0;
          position: relative;
          z-index: 2;

          .funList {
            width: 47%;
            text-align: center;
            color: #fff;
            margin-bottom: 10px;
            padding: 10px 0;
            border-radius: 3px;

            img {
              width: 35%;
              margin-bottom: 3px;
            }

            .blackDiv {
              width: 10%;
              height: 0.5px;
              margin: 0 auto;
              background: #e4e3e3;
              border-radius: 1px;
              margin-bottom: 4px;
            }

            .funTitle {
              font-size: 6px;
              font-weight: bold;
              margin-bottom: 2px;
            }

            .funDesc {
              font-size: 4.5px;
              color: #dddada;
            }
          }

          .funList:first-child {
            background: #049d98;
          }

          .funList:nth-child(2) {
            background: #3ab57f;
          }

          .funList:nth-child(3) {
            margin-bottom: 0px;
            background: #c59243;
          }

          .funList:last-child {
            margin-bottom: 0px;
            background: #21778d;
          }
        }

        .funImgView {
          width: 50%;
          // background: red;
          position: relative;
        }

        .funImgView img {
          // width: 120%;
          height: 105%;
          position: absolute;
          top: -10px;
          left: -40px;
          z-index: 1;
        }
      }
    }

    // 科室
    .deptView {
      width: 100%;
      background: #dff1f5;
      padding-bottom: 10px;

      .deptTitle {
        width: 100%;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        margin-top: 20px;
        letter-spacing: 1px;
      }

      .deptTitle span:first-child {
        color: #020202;
      }

      .deptTitle span:last-child {
        color: #049d98;
      }

      .deptTitleDesc {
        width: 100%;
        text-align: center;
        font-size: 4px;
        color: #aaa;
        margin-top: 1px;
      }

      .deptTitleIntro {
        width: 100%;
        text-align: center;
        font-size: 6px;
        color: #aaa;
        margin-top: 5px;
      }

      .deptMajor {
        width: 65%;
        margin: 10px auto;
        display: flex;
        justify-content: space-between;
        // background: rgba(70, 69, 69, 0.2);
        // height: 100px;
      }

      // 选中
      .deptMajListActice {
        width: 40%;
        // height: 100px;
        background-image: url("../assets/images/home_dep_acbg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: 100% 100%;
        position: relative;

        .deptMajTitleAct {
          position: relative;
          top: 5px;
          color: #fff;
          font-size: 8px;
          font-weight: bold;
          left: 10px;

          img {
            position: relative;
            height: 25px;
          }

          span {
            position: relative;
            top: -8px;
            left: 3px;
          }
        }

        .deptMaj-car {
          width: 100%;
          // margin-top: -17px;
          height: 115px;
          overflow-y: auto;

          // background: #3AB57F;
          .el-carousel--horizontal {
            padding-top: 20px;
          }

          /deep/ .el-carousel__container {
            height: 110px !important;
            // background: red;
            padding-top: 10px;
          }

          /deep/ .el-carousel__indicators--horizontal {
            width: calc(100% - 10px);
            text-align: right;
            border-radius: 0 0 10px 10px;
            padding-right: 10px;
            height: 20px;
            // background: rgba(255, 255, 255, .5);
            line-height: 20px;
            position: absolute;
            top: -0px;
          }

          /deep/ .el-carousel__indicator--horizontal {
            padding: 0;
            padding-right: 2px;
          }

          /deep/ .el-carousel__arrow:hover {
            width: 15px !important;
            height: 15px !important;
          }

          /deep/ .el-carousel__arrow {
            width: 15px !important;
            height: 15px !important;
          }

          // /deep/ .el-carousel__arrow{
          //     width: 10px !important;
          //     height: 10px !important;
          // }

          /deep/ .el-carousel__button {
            width: 4px;
            height: 4px;
            border-radius: 50%;
          }

          .deptListView {
            width: calc(100% - 10px);
            margin: 0 5px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-top: 5px;
            max-height: 100px;
            overflow-y: auto;

            .deptList {
              width: calc(48% - 8px);
              padding: 0 4px;
              // text-align: center;
              height: 13px;
              line-height: 13px;
              border-radius: 1px;
              // margin-top: 5px;
              margin-bottom: 5px;
              color: #eee;
              font-size: 4.5px;
              background-color: rgba(255, 255, 255, 0.2);
              display: flex;
              justify-content: space-between;

              div {
                width: 95%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

              img {
                width: 6px;
                height: 6px;
                margin-top: 3px;
                position: relative;
                left: 3px;
              }
            }
          }
          .deptListView::-webkit-scrollbar {
            width: 1px;
          }
          .deptListView::-webkit-scrollbar-thumb {
            background-color: #049d98;
          }
        }
      }

      // 未选中
      .deptMajList {
        width: 18.5%;
        background-image: url("../assets/images/home_dep_noA.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: 100% 100%;
        text-align: center;

        .deptMajTitle {
          width: 100%;
          font-size: 8px;
          font-weight: bold;
          color: #01312f;
        }

        img {
          width: 36px;
          height: 36px;
          margin-top: 60%;
          margin-bottom: 6px;
        }
      }
    }
  }

  // 专家
  .expertView {
    width: 100%;
    // background: #1b7f96;
    padding-bottom: 10px;

    // margin-bottom: 100px;
    .expertTitle {
      width: 100%;
      text-align: center;
      font-size: 12px;
      font-weight: bold;
      margin-top: 20px;
      letter-spacing: 1px;
    }

    .expertTitle span:first-child {
      color: #020202;
    }

    .expertTitle span:last-child {
      color: #049d98;
    }

    .expertTitleDesc {
      width: 100%;
      text-align: center;
      font-size: 4px;
      color: #aaa;
      margin-top: 1px;
    }

    .expertTiView {
      width: 280px;
      border: 0.1px solid #dff1f5;
      margin: 10px auto 15px;
      display: flex;
      justify-content: space-between;
      // background: rgba(70, 69, 69, 0.2);
      border-radius: 10px;
      height: 15px;
      line-height: 15px;

      // height: 100px;
      .expertTiList {
        width: 33.3%;
        border-radius: 10px;
        text-align: center;
        font-size: 6px;
        font-weight: bold;
        color: #01312f;
        position: relative;

        img {
          width: 11px;
          position: absolute;
          top: 2.5px;
          margin-right: 2px;
        }

        span {
          margin-left: 12px;
          margin-top: 5px;
        }
      }

      .expertTiListActive {
        width: 33.3%;
        border-radius: 10px;
        text-align: center;
        font-size: 6px;
        font-weight: bold;
        color: #fff;
        background: #049d98;
        position: relative;

        img {
          width: 11px;
          position: absolute;
          top: 2.5px;
          margin-right: 2px;
        }

        span {
          margin-left: 12px;
          margin-top: 5px;
        }
      }
    }

    .expertSwiView {
      width: 100%;

      // height: 50px;
      // background: #089C98;
      /deep/ .el-carousel__container {
        height: 111px !important;
        overflow: hidden;
        // background: red;
      }
      /deep/ .el-carousel__indicator--horizontal {
        padding: 0;
        padding-right: 2px;
        position: relative;
        top: 0px;
      }

      /deep/ .el-carousel__button {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #aaa;
        // margin-top: 3px;
      }

      /deep/ .el-carousel__arrow:hover {
        width: 15px !important;
        height: 15px !important;
      }

      /deep/ .el-carousel__arrow {
        width: 15px !important;
        height: 15px !important;
      }

      // /deep/ .el-carousel__indicators--horizontal {
      //     width: calc(100% - 10px);
      //     text-align: right;
      //     border-radius: 0 0 10px 10px;
      //     padding-right: 10px;
      //     height: 20px;
      //     line-height: 20px;
      // }
      // /deep/ .el-carousel__indicator--horizontal{
      //     padding: 0;
      //     padding-right: 2px;
      // }

      // /deep/ .el-carousel__button {
      //     width: 4px;
      //     height: 4px;
      //     border-radius: 50%;
      // }
      .expertListView {
        width: 98%;
        height: 100px !important;
        margin: 0 auto;
        margin-top: 3px;
        display: flex;
        // justify-content: space-between;
      }

      .expertList {
        width: 32%;
        height: 100px !important;
        display: flex;
        justify-content: space-between;
        // background: #fcfcfc;
        box-shadow: 0px 0px 0.7px 0.2px #949696;
        margin-left: 5px;

        .expertListLef {
          width: 70px;
          height: 100px;
          background: #089c98;
          overflow: hidden;

          .expertImg {
            width: 100%;
            height: 100%;
          }
        }

        .expertListRig {
          width: calc(100% - 70px);
          padding: 5px;

          // background: #e7e9e9;
          .expertName {
            color: #01312f;
            font-size: 8px;
            font-weight: bold;
            margin-bottom: 5px;

            span {
              font-size: 4px;
              font-weight: normal;
              color: #7f8383;
              position: relative;
              top: -1px;
              left: 5px;
            }
          }

          .expertZc {
            color: #01312f;
            font-size: 5px;
            margin-right: 3px;
            margin-bottom: 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .expertSc {
            color: #01312f;
            height: 63px;
            font-size: 4.5px;
            margin-right: 3px;
            margin-bottom: 10px;
            line-height: 8px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 8;
            overflow: hidden;
          }
        }
      }
    }
  }

  // 健康宣教
  .healthPropView {
    width: calc(100% - 20px);
    background: #dff1f5;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    // height: 180px;
    padding: 20px 10px 30px;
    background-image: url("../assets/images/h_xj_bg.png");
    background-repeat: no-repeat;
    background-size: 100% 30%;
    background-position: 100% 100%;

    .healthView-w {
      width: 65%;
      margin: 0 auto;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      // background: red;
    }

    .healthView {
      width: 52%;

      // height: 65px;
      .healthTitle {
        width: calc(100% - 20px);
        padding: 0px 5px 3px;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1px;
      }

      .healthTitle span:first-child {
        color: #020202;
      }

      .healthTitle span:last-child {
        color: #049d98;
      }

      .healthTitleDesc {
        width: 100%;
        font-size: 4px;
        color: #aaa;
        padding: 0px 5px 5px;
      }

      // background: #fff;
      .healthDetailListAct {
        width: calc(100% - 20px);
        padding: 0px 10px 5px;
        text-align: left;
        display: flex;
        justify-content: space-between;

        .healthTimerAct {
          width: 30px;
          height: 24px;
          border: 0.1px solid #929494;
          border-radius: 1px;
          box-sizing: border-box;
          display: flex;

          img {
            height: 20px;
            position: relative;
            left: 1px;
            top: 2px;
          }

          .healthTimerDAct {
            margin-top: 3px;
            color: #089291;

            span {
              display: block;
              margin-left: 1px;
              font-size: 7px;
              font-weight: bold;
            }

            span:last-child {
              font-size: 5px;
              font-weight: normal;
              margin-top: 2px;
            }
          }
        }

        .healthTextAct {
          width: calc(100% - 35px);
          padding-bottom: 5px;
          border-bottom: 0.1px solid #eee;

          .healthTextTitleAct {
            color: #01312f;
            font-size: 6px;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .healthTextDescAct {
            font-size: 4.5px;
            height: 14px;
            line-height: 7px;
            margin-top: 4px;
            color: #5a7d7e;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
        }
      }

      .healthDetailList {
        width: calc(100% - 20px);
        padding: 0px 10px 5px;
        text-align: left;
        display: flex;
        justify-content: space-between;

        .healthTimer {
          // border: 0.1px solid #eee;
          border-radius: 1px;
          box-sizing: border-box;
          display: flex;

          img {
            height: 4px;
            position: relative;
            left: 1px;
            top: 1.5px;
          }
        }

        .healthText {
          width: calc(100% - 10px);
          padding-bottom: 5px;
          border-bottom: 0.1px solid #eee;

          .healthTextTitle {
            color: #01312f;
            font-size: 5px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .healthImgView {
      width: 160px;
      height: 160px;
      // background: #1E4743;
      background-image: url("../assets/images/h_xj_rbg.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: 0%;
      text-align: center;

      .hea_Img_View {
        width: 140px;
        height: 140px;
        margin-top: 10px;
        margin-left: 10px;
        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .listEmpty {
    width: 100%;

    // padding-top: 20px;
    img {
      width: 40px !important;
      margin-top: 20px !important;
    }
  }

  .carouselViewNo {
    height: 100px;

    .noDataView {
      line-height: 100px;
      font-size: 4.5px;
      color: #01312f;
      background: rgba(0, 0, 0, 0.3);
    }
  }
}
// 移动
.m-indexView {
  width: 100%;
  // height: 500px;
  // background: rgb(202, 196, 196);
  margin-top: 38px;
  // banner
  .m-carouselImgs {
    width: 100%;
    /deep/ .el-carousel__container {
      height: 54px !important;
      // background: red;
    }
    /deep/ .el-carousel__indicator--horizontal {
      padding: 5px !important;
    }
    /deep/ .el-carousel__button {
      width: 5px !important;
      height: 5px !important;
      border-radius: 50% !important;
    }
    img {
      width: 100%;
    }
  }
  .searchValue {
    width: 98%;
    margin: 10px auto;
    padding-left: 3px;
    border: 0.1px solid #eee;
    border-bottom: 1px solid #089291;
    .selectSeaView {
      position: relative;
      top: 1px;
    }
    .searchIpt {
      padding: 6px 2px;

      /deep/ .el-input {
        font-size: 0;
      }

      /deep/ .el-input__inner {
        height: 15px !important;
        font-size: 5px !important;
        border: 0.1px solid #eee;
        border-radius: 2px;
      }
    }

    .searchBtn {
      padding: 6px 0px;

      .el-button {
        height: 12px !important;
        line-height: 12px !important;
        font-size: 5px !important;
        padding: 0px 4px !important;
        border-radius: 2px;
        position: relative;
        top: 2px;
      }

      /deep/ .el-button [class*="el-icon-"] + span {
        margin-left: 0px !important;
      }

      .el-button--primary {
        color: #fff;
        background-color: #089291;
        border-color: #089291;
      }
    }
  }
  .m-newView,
  .m-deptView,
  .m-healthPropView,
  .m-exportPropView {
    width: 100%;
    margin-top: 5px;
  }
  // 新闻中心
  .m-newView {
    .m-nTitle {
      background: #089291;
      color: #fff;
      font-size: 6px;
      padding: 3px 5px;
      span:first-child {
        background: #fff;
        display: inline-block;
        width: 1px;
        height: 8px;
        position: relative;
        top: 2px;
        border-radius: 1px;
        margin-right: 3px;
      }
    }
    .opTabsView {
      margin-top: -1px;
      box-shadow: 0px 0px 1px 0.2px rgba(8, 146, 145, 0.3);
      padding-top: 5px;
      .newsSwiper {
        width: calc(100% - 10px);
        margin: 0 5px;
        // border: 0.1px solid #3AB57F;
        background: #fff;
        margin-bottom: 5px;
        margin-top: 2px;

        .newsImg {
          width: 100%;
          height: 100%;
          border-radius: 0px;
        }

        .newsTitle {
          position: relative;
          top: -20px;
          height: 20px;
          width: 100%;
          line-height: 20px;
          color: rgba(255, 255, 255, 1);
          background-color: rgba(77, 75, 75, 0.5);
          font-size: 6px;
          font-weight: bold;

          .text {
            width: 67%;
            padding-left: 2px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        /deep/ .el-carousel__container {
          height: 80px !important;
          // background: red;
        }

        img {
          width: 100%;
        }

        /deep/ .el-carousel__indicators--horizontal {
          width: calc(100% - 10px);
          text-align: right;
          border-radius: 0 0 10px 10px;
          padding-right: 10px;
          height: 20px;
          line-height: 20px;
        }

        /deep/ .el-carousel__indicator--horizontal {
          padding: 0;
          padding-right: 2px;
        }

        /deep/ .el-carousel__button {
          width: 4px;
          height: 4px;
          border-radius: 50%;
        }
      }
      /deep/.el-tabs--border-card {
        border: 0 solid #dcdfe6 !important;
        -webkit-box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.12),
          0 0 0 0 rgba(0, 0, 0, 0.04) !important;
        box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.12),
          0 0 0px 0 rgba(0, 0, 0, 0.04) !important;
      }

      /deep/ .el-tabs__nav-scroll {
        display: flex !important;
        justify-content: center !important;
      }

      /deep/ .el-tabs__item {
        height: 12px !important;
        line-height: 12px !important;
        font-size: 6px !important;
        padding: 0 5px !important;
        max-width: 50px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      /deep/ .el-tabs__nav {
        margin: 0 auto !important;
        border: 0.1px solid #089291;
        box-sizing: border-box;
        border-radius: 10px;
        height: 12px;
      }

      /deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
        color: #fff !important;
        background-color: #089291 !important;
        border-radius: 10px !important;
      }

      /deep/ .el-tabs--border-card > .el-tabs__header {
        background-color: #fff !important;
      }

      /deep/ .el-tabs--border-card > .el-tabs__header {
        border: none;
      }

      /deep/ .el-tabs--border-card {
        border: none;
      }

      /deep/ .el-tabs__nav-next {
        line-height: 13px;
        font-size: 6px;
        color: #089291;
      }

      /deep/ .el-tabs__nav-prev {
        line-height: 13px;
        font-size: 6px;
        color: #089291;
      }

      /deep/.el-tabs__nav-wrap.is-scrollable {
        padding: 0px 10px;
      }

      /deep/ .el-tabs__content {
        padding: 5px;
      }

      /deep/
        .el-tabs--border-card
        > .el-tabs__header
        .el-tabs__item:not(.is-disabled):hover {
        color: #089291 !important;
      }
      /deep/
        .el-tabs--border-card
        > .el-tabs__header
        .el-tabs__item.is-active:hover {
        color: #fff !important;
      }
    }
    .m-healthMajListView {
      position: relative;
      width: calc(100% - 10px);
      padding: 5px;
      padding-bottom: 0px;
      border-radius: 2px;
      //   box-shadow: 0px 0px 1px 0.2px rgba(8, 146, 145, 0.3);
      margin-top: -1px;
      // background: #1e4743;

      .m-healthDetailList {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 5px;
        margin-bottom: 4px;
        color: #1e4743;
        .m-healthImg {
          width: 5px;
          img {
            width: 5px;
          }
        }
        .m-healthText {
          width: calc(100% - 45px);
          white-space: nowrap;
          overflow: hidden; //文本超出隐藏
          text-overflow: ellipsis; //文本超出省略号代替
        }
        .m-healthTimer {
          width: 36px;
          text-align: right;
        }
      }
    }
    // .m-nListView {
    //   width: 100%;
    //   display: flex;
    //   justify-content: space-between;
    //   margin-top: 3px;
    //   flex-wrap: wrap;
    //   .m-nList {
    //     width: 49%;
    //     height: 30px;
    //     line-height: 30px;
    //     text-align: center;
    //     color: #fff;
    //     font-size: 6px;
    //     margin-bottom: 5px;
    //     background: radial-gradient(
    //       at 3px 3px,
    //       rgb(37, 168, 124),
    //       rgb(23, 104, 93),
    //       rgb(8, 73, 43),
    //       rgb(37, 168, 168)
    //     );
    //     // background-image: url("../assets/images/h_new_bg.png");
    //     // background-repeat: no-repeat;
    //     // background-size: 100% 100%;
    //     // background-position: 100% 100%;
    //     white-space: nowrap;
    //     overflow: hidden; //文本超出隐藏
    //     text-overflow: ellipsis; //文本超出省略号代替
    //   }
    // }
  }
  // 科室中心
  .m-deptView {
    margin-top: 10px;
    .m-dTitle {
      background: #089291;
      color: #fff;
      font-size: 6px;
      padding: 3px 5px;
      span:first-child {
        background: #fff;
        display: inline-block;
        width: 1px;
        height: 8px;
        position: relative;
        top: 2px;
        border-radius: 1px;
        margin-right: 3px;
      }
    }
    .m-deptMajListView {
      width: 100%;
      margin: 5px auto;
      display: flex;
      justify-content: space-between;
      .m-deptMajList {
        width: 23%;
        height: 80px;
        background-image: url("../assets/images/home_dep_noA.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: 100% 100%;
        text-align: center;
        box-shadow: 0px 0px 1px 0.2px rgba(8, 146, 145, 0.3);
        border-radius: 2px;
        .m-deptMajTitleView {
          margin-top: -3px;
        }
        .m-deptMajTitle {
          width: 100%;
          font-size: 5px;
          font-weight: bold;
          color: #01312f;
        }

        img {
          width: 26px;
          height: 26px;
          margin-top: 50%;
          margin-bottom: 6px;
        }
      }
    }
  }
  // 专家介绍
  .m-exportPropView {
    width: 100%;
    margin-top: 10px;
    box-shadow: 0px 0px 1px 0.2px rgba(8, 146, 145, 0.6);
    padding-bottom: 5px;
    .m-eTitle {
      background: #089291;
      color: #fff;
      font-size: 6px;
      padding: 3px 5px;
      span:first-child {
        background: #fff;
        display: inline-block;
        width: 1px;
        height: 8px;
        position: relative;
        top: 2px;
        border-radius: 1px;
        margin-right: 3px;
      }
    }

    // margin-bottom: 100px;
    .m-expertTitle {
      width: 100%;
      text-align: center;
      font-size: 12px;
      font-weight: bold;
      margin-top: 20px;
      letter-spacing: 1px;
    }

    .m-expertTitle span:first-child {
      color: #020202;
    }

    .m-expertTitle span:last-child {
      color: #049d98;
    }

    .m-expertTitleDesc {
      width: 100%;
      text-align: center;
      font-size: 4px;
      color: #aaa;
      margin-top: 1px;
    }
    .m-expertView {
      width: calc(100% -10px);
      padding: 0 5px;
    }

    .m-expertTiView {
      width: 100%;
      border: 0.1px solid #dff1f5;
      margin: 5px auto;
      display: flex;
      justify-content: space-between;
      // background: rgba(70, 69, 69, 0.2);
      border-radius: 10px;
      height: 13px;
      line-height: 14px;

      // height: 100px;
      .m-expertTiList {
        width: 33.3%;
        border-radius: 10px;
        text-align: center;
        font-size: 6px;
        font-weight: bold;
        color: #01312f;
        position: relative;

        img {
          width: 8px;
          position: absolute;
          top: 2px;
          margin-right: 0px;
        }

        span {
          margin-left: 9px;
          margin-top: 5px;
        }
      }

      .m-expertTiListActive {
        width: 33.3%;
        border-radius: 10px;
        text-align: center;
        font-size: 6px;
        font-weight: bold;
        color: #fff;
        background: #049d98;
        position: relative;

        img {
          width: 8px;
          position: absolute;
          top: 2px;
          margin-right: 0px;
        }

        span {
          margin-left: 9px;
          margin-top: 5px;
        }
      }
    }

    .m-expertSwiView {
      width: 100%;
      /deep/ .el-carousel__container {
        height: 95px !important;
        overflow: hidden;
        // background: red;
      }
      //   height: 106px;
      // height: 50px;
      // background: #089C98;

      // /deep/ .el-carousel__indicators--horizontal {
      //     width: calc(100% - 10px);
      //     text-align: right;
      //     border-radius: 0 0 10px 10px;
      //     padding-right: 10px;
      //     height: 20px;
      //     line-height: 20px;
      // }
      /deep/ .el-carousel__indicator--horizontal {
        padding: 0;
        padding-right: 2px;
      }

      /deep/ .el-carousel__button {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #089291;
      }

      /deep/ .el-carousel__arrow:hover {
        width: 15px !important;
        height: 15px !important;
      }

      /deep/ .el-carousel__arrow {
        width: 15px !important;
        height: 15px !important;
      }
      .m-expertListView {
        width: 98%;
        margin: 0 auto;
        margin-top: 3px;
        display: flex;
        justify-content: space-between;
      }

      .m-expertList {
        width: 32%;
        // display: flex;
        // justify-content: space-between;
        // background: #fcfcfc;
        box-shadow: 0px 0px 0.7px 0.2px #949696;
        margin-left: 5px;

        .m-expertListLef {
          width: 100%;
          height: 60px;
          background: #089c98;
          overflow: hidden;

          .m-expertImg {
            width: 100%;
            height: 100%;
          }
        }

        .m-expertListRig {
          width: calc(100% - 2px);
          padding: 2px;

          // background: #e7e9e9;
          .m-expertName {
            color: #089291;
            font-size: 6px;
            margin-bottom: 2px;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .m-expertZc {
            color: #089291;
            font-size: 5px;
            margin-bottom: 3px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }
  }
  // 功能介绍
  .m-funPropView {
    width: 100%;
    margin-top: 10px;
    .m-fList {
      width: 100%;
      height: 25px;
      display: flex;
      justify-content: space-between;
      font-size: 6px;
      color: #fff;
      margin-bottom: 5px;
      border-radius: 2px;
      .m-f-t-d {
        width: 60%;
        margin-left: 5px;
        .m-f-t {
          font-size: 6px;
          margin-top: 4px;
          margin-bottom: 2px;
        }
        .m-f-d {
          font-size: 5px;
        }
      }
      .imgF {
        width: 15px;
        height: 15px;
        margin-right: 5px;
        margin-top: 4px;
      }
    }
    .m-fList:first-child {
      background: rgba(4, 157, 152, 0.8);
    }

    .m-fList:nth-child(2) {
      background: rgba(58, 181, 127, 0.8);
    }

    .m-fList:nth-child(3) {
      background: rgba(197, 146, 67, 0.8);
    }

    .m-fList:last-child {
      background: rgba(33, 119, 141, 0.8);
    }
  }
  // 健康宣教
  .m-healthPropView {
    margin-top: 10px;
    .m-hTitle {
      background: #089291;
      color: #fff;
      font-size: 6px;
      padding: 3px 5px;
      span:first-child {
        background: #fff;
        display: inline-block;
        width: 1px;
        height: 8px;
        position: relative;
        top: 2px;
        border-radius: 1px;
        margin-right: 3px;
      }
    }
    .m-healthMajListView {
      width: calc(100% - 10px);
      padding: 5px;
      border-radius: 2px;
      box-shadow: 0px 0px 1px 0.2px rgba(8, 146, 145, 0.3);
      margin-top: -1px;
      // background: #1e4743;

      .m-healthDetailList {
        width: 100%;
        display: flex;
        justify-content: space-between;
        font-size: 5px;
        margin-bottom: 4px;
        color: #1e4743;
        .m-healthImg {
          width: 5px;
          img {
            width: 5px;
          }
        }
        .m-healthText {
          width: calc(100% - 45px);
          white-space: nowrap;
          overflow: hidden; //文本超出隐藏
          text-overflow: ellipsis; //文本超出省略号代替
        }
        .m-healthTimer {
          width: 36px;
          text-align: right;
        }
      }
    }
  }
  .noDataMob {
    width: 100%;
    font-size: 6px;
    font-weight: bold;
    text-align: center;
    line-height: 20px;
    color: #089291;
  }
  /deep/ .el-button--primary {
    border-color: #089291 !important;
    border-width: 0.1px !important;
    background-color: #fff !important;
    color: #089291 !important;
    font-size: 5px !important;
    padding: 2px 10px !important;
    border-radius: 10px !important;
  }
  /deep/ .el-button [class*="el-icon-"] + span {
    margin-left: 1px;
  }
  .m-more-btn {
    width: 100%;
    text-align: center;
    margin-top: 5px;
  }
}
.imgNo {
    width: 100%;
    line-height: 90px;
    text-align: center;
    color: rgba(232, 236, 236, 0.4);
    font-size: 5px;
  }
  .imgNoMob {
    width: 100%;
    line-height: 60px;
    text-align: center;
    color: rgba(232, 236, 236, 0.4);
    font-size: 5px;
  }
</style>