import { render, staticRenderFns } from "./searchPage.vue?vue&type=template&id=5faa6dba&scoped=true"
import script from "./searchPage.vue?vue&type=script&lang=js"
export * from "./searchPage.vue?vue&type=script&lang=js"
import style0 from "./searchPage.vue?vue&type=style&index=0&id=5faa6dba&prod&lang=css"
import style1 from "./searchPage.vue?vue&type=style&index=1&id=5faa6dba&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5faa6dba",
  null
  
)

export default component.exports