<!--
 * @Author: 1697482796@qq.com 1697482796@qq.com
 * @Date: 2023-02-08 12:24:15
 * @LastEditors: 1697482796@qq.com 1697482796@qq.com
 * @LastEditTime: 2023-03-31 18:07:23
 * @FilePath: \sdy_webpack1\src\pages\optionList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="optionList">
    <!-- 背景图 -->
    <div class="backgrpund_img"></div>
    <div class="blackDiv"></div>
    <!-- 搜索框 -->
    <div class="searchValue">
      <el-row type="flex" class="row-bg" justify="center">
        <el-col :span="2" class="selectSeaView">
          <el-select
            v-model="selectValue"
            placeholder="请选择"
            @change="selectChange"
          >
            <el-option
              v-for="item in searchOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="13">
          <div class="searchIpt">
            <el-input placeholder="请输入内容" v-model="searchValue"></el-input>
          </div>
        </el-col>
        <el-col :span="3">
          <div class="searchBtn">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="toSearchPage"
              >立即搜索</el-button
            >
          </div>
        </el-col>
      </el-row>
      <!-- <el-row type="flex" class="row-bg" justify="center">
        <el-col :span="15">
          <div class="searchIpt">
            <el-input placeholder="请输入内容" v-model="searchValue"></el-input>
          </div>
        </el-col>
        <el-col :span="3">
          <div class="searchBtn">
            <el-button type="primary" icon="el-icon-search" @click="toSearchPage">立即搜索</el-button>
          </div>
        </el-col>
      </el-row> -->
    </div>
    <!-- 内容 -->
    <div class="optionContainer">
      <div class="blackDiv"></div>
      <!-- 专家 -->
      <div class="expertView">
        <div class="expertTitle"><span>{{curTitle}}</span><span>专家列表</span></div>
        <div class="expertTitleDesc"></div>
        <div class="opTabsView">
          <!-- 内容 -->
          <div class="tabsContent">
            <!-- 列表 -->
            <div class="infoContent">
              <div class="zjIntInfoLists" v-if="expertList.length > 0">
                <div
                  class="infoFlex"
                  v-for="(item, index) in expertList"
                  :key="index"
                  @click="expertDetailClick(item)"
                >
                  <div class="zjList" v-if="index % 2 !== 0">
                    <div class="infoLeft">
                      <div class="doctorName">
                        {{ item.attributes.doctorName }}
                      </div>
                      <div class="txDiv titleDiv">
                        <div class="text txText">
                          {{ item.attributes.mentorLevel }}
                        </div>
                      </div>
                      <!-- <div class="yjDiv titleDiv">
                        <div class="text yjText">
                          {{ item.attributes.expertise }}
                        </div>
                      </div> -->
                      <div class="timerDiv">
                      </div>
                    </div>
                    <div class="imgDiv">
                      <img
                        v-if="item.attributes.photo.data"
                        :src="
                          imgUrl + item.attributes.photo.data.attributes.url
                        "
                        alt=""
                      />
                      <div v-else class="imgNo">暂无照片</div>
                      <!-- <div class="zjName">
                        {{ item.attributes.doctorName }}
                      </div> -->
                    </div>
                  </div>
                  <div class="zjList" v-else>
                    <div class="imgDiv">
                      <img
                        v-if="item.attributes.photo.data"
                        :src="
                          imgUrl + item.attributes.photo.data.attributes.url
                        "
                        alt
                      />
                      <div v-else class="imgNo">暂无照片</div>
                      <!-- <div class="zjName">
                        {{ item.attributes.doctorName }}
                      </div> -->
                    </div>
                    <div class="infoRight">
                      <div class="doctorName">
                        {{ item.attributes.doctorName }}
                      </div>
                      <div class="txDiv titleDiv">
                        <div class="text txText">
                          {{ item.attributes.mentorLevel }}
                        </div>
                      </div>
                      <!-- <div class="yjDiv titleDiv">
                        <div class="text yjText">
                          {{ item.attributes.expertise }}
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="listEmpty" v-else>
                <img src="../assets/icons/nodata.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <Footer></Footer>
  </div>
</template>

<script>
import axios from "axios";
// import Silder from "@/components/silder.vue";
import Footer from "@/components/footer.vue";
import { config } from "../api/config";
export default {
  name: "optionList",
  components: {
    Footer,
    // Silder,
  },
  data() {
    return {
      allMenus: [],
      menuCurObj: {},
      curTitle: "",
      searchValue: "",
      oneList: [],
      newsSwiperList: [],
      newsDetailList: [],
      exportMenusList: [],
      expertList: [],
      deptMenusList: [],
      deptDetailList: [],
      deptobj: {},
      exportActiveValue: "",
      deptEJshow: true,
      deptExpshow: false,
      // 分页
      newTotal: 0,
      curNewPage: 1,
      pageSize: 10,
      newZbTitle: "",
      searchOptions: [
        {
          label: "新闻",
          value: "article",
        },
        {
          label: "医生",
          value: "doctor",
        },
        {
          label: "科室",
          value: "department",
        },
      ],
      selectValue: "article",
      dsList: [],
    };
  },
  watch: {
    // searchVal: {
    //   handler(val) {
    //     if (val === "新闻") {
    //       this.getNewsList();
    //     }
    //     if (val === "专家") {
    //       this.getDoctors();
    //     }
    //   },
    // },
  },
  created() {
    this.imgUrl = config().imgserve;
    this.allMenus = JSON.parse(sessionStorage.getItem("allMenuList"));
    // console.log("000000", this.$route.query);
    this.curTitle = this.$route.query.list
    this.getExportList(this.$route.query.list);
    // this.getExportList()
  },
  methods: {
    // 按规定长度截取数组
    grouping(arr, len) {
      let col = Math.ceil(arr.length / len);
      let data = [];
      let start = 0;
      while (start < col) {
        let temp = arr.slice(start * len, (start + 1) * len);
        data[start] = temp;
        start++;
      }
      return data;
    },
    selectChange(e) {
      this.searchValue = "";
      // console.log("看看选择", e, this.selectValue);
    },
    // 搜索
    toSearchPage() {
      let selectStr = "";
      if (this.newShow || this.newNtabShow) {
        selectStr = "article";
      }
      if (this.exportShow || this.tutorShow) {
        selectStr = "doctor";
      }
      if (this.deptShow) {
        selectStr = "department";
      }
      this.$router.push({
        path: "/searchPage",
        query: {
          title: "搜索",
          value: selectStr,
          keyword: this.searchValue,
        },
      });
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      // console.log("看看标题", this.$route.query.list.includes("招标"));
      this.curNewPage = val;
      if (this.$route.query.list.includes("招标")) {
        if (this.newZbTitle.includes("招标")) {
          this.getZbNewsList(this.newZbTitle, this.pageSize, this.curNewPage);
        } else {
          this.getNewsList(this.newZbTitle, this.pageSize, this.curNewPage);
        }
        // this.getZbNewsList(this.$route.query.list, this.pageSize, this.curNewPage)
      } else {
        if (this.newZbTitle.includes("招标")) {
          this.getZbNewsList(this.newZbTitle, this.pageSize, this.curNewPage);
        } else {
          this.getNewsList(this.newZbTitle, this.pageSize, this.curNewPage);
        }
      }
    },
    // 专家列表
    getExportList(val) {
      this.expertList = [];
      let api = "";
      api =
        "/api/doctors?pagination[pageSize]=1000&pagination[page]=1&populate=*&sort=sortNo:desc&filters[departments][name][$eq]=" +
        val;
      axios
        .get(config().urlServe + api)
        .then((res) => {
          // console.log("专家数据", val, res.data.data);
          if (res.status === 200) {
            if (res.data.data) {
              this.expertList = res.data.data;
            }
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    // 专家详情
    expertDetailClick(e) {
    // console.log('000999', this.$route.query)
      this.$router.push({
        path: "/expertDetail",
        query: {
          list: e.attributes.doctorName,
          id: e.id,
          title: this.$route.query.title,
        },
      });
    },
    // 返回首页
    goIndex() {
      this.$router.push({
        path: "/index",
      });
    },
  },
};
</script>
<style>
.el-select-dropdown__item {
  padding: 0 5px !important;
  font-size: 5px !important;
  height: 8px !important;
  line-height: 8px !important;
  border-radius: 2px !important;
  text-align: center !important;
}
.el-select-dropdown__list {
  padding: 5px 0px !important;
}
.el-select-dropdown {
  min-width: 35px !important;
  border: 0.1px solid #eee !important;
}
.el-popper[x-placement^="bottom"] {
  margin-top: 2px !important;
}
.el-select-dropdown__item.selected {
  color: #089291 !important;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
// @media screen and (max-width: 5000px) {
//   .optionContainer {
//     width: 80%;
//   }
// }
// @media screen and (max-width: 1500px) {
//   .optionContainer {
//     width: 90%;
//   }
// }
// @media screen and (max-width: 900px) {
//   .optionContainer {
//     width: 98%;
//   }
// }
.optionList {
  width: 100%;
  position: relative;

  .backgrpund_img {
    position: absolute;
    background: #dff1f5;
    top: 0px;
    height: 100px;
    width: 100%;
    background-image: url("../assets/images/h_xj_bg.png");
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .blackDiv {
    height: 25px;
  }

  .optionContainer {
    width: 65%;
    margin: 0 auto;
    margin-top: 25px;
    box-shadow: 2px 0px 10px 1px rgba(187, 188, 189, 0.4);

    // 专家
    .expertView {
      width: 100%;
      // background: #1b7f96;
      padding-bottom: 10px;

      // margin-bottom: 100px;
      .expertTitle {
        width: 100%;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        margin-top: 1px;
        letter-spacing: 1px;
      }

      .expertTitle span:first-child {
        color: #049d98;
      }

      .expertTitle span:last-child {
        color: #020202;
      }

      .expertTitleDesc {
        width: 100%;
        text-align: center;
        font-size: 4px;
        color: #aaa;
        margin-top: 1px;
        margin-bottom: 10px;
      }

      .expertTiView {
        width: 280px;
        border: 0.1px solid #dff1f5;
        margin: 10px auto 5px;
        display: flex;
        justify-content: space-between;
        // background: rgba(70, 69, 69, 0.2);
        border-radius: 10px;
        height: 15px;
        line-height: 15px;

        // height: 100px;
        .expertTiList {
          width: 33.3%;
          border-radius: 10px;
          text-align: center;
          font-size: 6px;
          font-weight: bold;
          color: #01312f;
          position: relative;

          img {
            width: 11px;
            position: absolute;
            top: 2.5px;
            margin-right: 2px;
          }

          span {
            margin-left: 12px;
            margin-top: 5px;
          }
        }

        .expertTiListActive {
          width: 33.3%;
          border-radius: 10px;
          text-align: center;
          font-size: 6px;
          font-weight: bold;
          color: #fff;
          background: #049d98;
          position: relative;

          img {
            width: 11px;
            position: absolute;
            top: 2.5px;
            margin-right: 2px;
          }

          span {
            margin-left: 12px;
            margin-top: 5px;
          }
        }
      }

      .infoContent {
        width: calc(100% - 10px);
        padding: 0px 5px;
        max-height: 245px;
        overflow: hidden;
        box-shadow: 0px 0px 3px 1px #eee;
      }

      .zjIntInfoLists {
        width: calc(100% - 10px);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 10px;
        max-height: 230px;
        overflow: hidden;
        overflow-y: auto;
        padding: 5px;

        .infoFlex {
          width: 49%;
        }

        .zjList {
          widows: 100%;
          // border-radius: 3px;
          box-shadow: 0px 0px 2px 1px #d1d0d0;
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-between;
          margin-bottom: 10px;
          // padding: 10px 5px;

          // height: 123px;
          // .imgDiv {
          //       height: 203px;
          //       width: 145px;
          //       img {
          //         height: 203px;
          //         width: 145px;
          //       }
          .imgDiv {
            height: 69px;
            width: 50px;
            // width: 21%;
            // border-radius: 3px;
            overflow: hidden;
            position: relative;
            border: 0.1px solid #d1d0d0;
            box-sizing: border-box;

            img {
              width: 100%;
            }

            .zjName {
              width: 100%;
              height: 13px;
              line-height: 13px;
              position: absolute;
              bottom: 0px;
              background-color: rgba(39, 41, 41, 0.4);
              text-align: center;
              color: #fff;
              font-size: 6px;
            }
          }

          .infoLeft {
            width: calc(79% - 10px);
            margin-right: 5px;
            position: relative;
            padding: 3px 0px;
            margin-left: 5px;
          }
          .infoRight {
            width: calc(79% - 10px);
            margin-left: 5px;
            position: relative;
            padding: 3px 0px;
            margin-right: 5px;
          }
          .doctorName {
            color: #01312f;
            font-size: 8px;
            font-weight: bold;
            margin-bottom: 5px;
            margin-top: 2px;
          }

          .timerDiv {
            position: absolute;
            bottom: 0px;
          }

          .title {
            display: inline-block;
            width: 20px;
            text-align: center;
            height: 8px;
            line-height: 8px;
            border-radius: 5px;
            color: #fff;
            font-size: 4px;
          }

          .txTitle {
            background-color: #049d98;
          }

          .yjTitle {
            background-color: #2f75bc;
          }

          .tmTitle {
            background-color: #dd5b25;
          }

          .text {
            color: #5b5b5b;
            line-height: 8px;
            margin-top: 2px;
            font-size: 4.4px;
          }

          .txText {
            margin-bottom: 3px;
            // 多行省略
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }

          .yjText {
            // 多行省略
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            overflow: hidden;
          }
        }
      }

      .zjIntInfoLists::-webkit-scrollbar {
        width: 1px;
      }

      .zjIntInfoLists::-webkit-scrollbar-thumb {
        background-color: #049d98;
      }
    }
  }

  // 搜索框
  .searchValue {
    width: 65%;
    margin: 10px auto;
    border: 0.1px solid #eee;
    border-bottom: 1px solid #089291;
    background: #fff;
    position: relative;
    z-index: 100;
    .selectSeaView {
      position: relative;
      top: 4px;
    }
    /deep/ .el-input--suffix .el-input__inner {
      padding-right: 0px !important;
    }
    /deep/ .el-input__inner {
      padding: 0px 5px !important;
      height: 14px !important;
      line-height: 14px !important;
      border-radius: 2px !important;
      border: 0.1px solid #eee !important;
      font-size: 6px !important;
      color: #089291 !important;
    }
    /deep/ .el-select .el-input .el-select__caret {
      line-height: 8px !important;
      font-size: 8px !important;
    }
    /deep/ .el-input__icon {
      width: 10px !important;
    }
    .searchIpt {
      padding: 10px;

      /deep/ .el-input {
        font-size: 0;
      }

      /deep/ .el-input__inner {
        height: 15px !important;
        font-size: 5px !important;
        border: 0.1px solid #eee;
        border-radius: 2px;
      }
    }

    .searchBtn {
      padding: 10px 0px;

      .el-button {
        height: 15px !important;
        line-height: 15px !important;
        font-size: 5px !important;
        padding: 0px 4px !important;
        border-radius: 2px;
      }

      /deep/ .el-button [class*="el-icon-"] + span {
        margin-left: 0px !important;
      }

      .el-button--primary {
        color: #fff;
        background-color: #089291;
        border-color: #089291;
      }
    }
  }
  .listEmpty {
    width: 100%;
    text-align: center;
    // padding-top: 20px;
    img {
      width: 40px !important;
      margin-top: 20px !important;
    }
  }

  .imgNo {
    width: 100%;
    line-height: 70px;
    text-align: center;
    color: rgba(39, 41, 41, 0.4);
    font-size: 5px;
  }
}
</style>