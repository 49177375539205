const baseSize = 16
// 设置rem函数
function setRem () {
    // 当前页面宽度相当于1920宽的缩放比例
    const scale = document.documentElement.clientWidth / 1920
    // 设置页面根节点字体大小
    document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px'
}
// 初始化
setRem()
// 改变窗口大小时重新设置rem
window.onresize = function () {
    // setRem()
}
// 设置 rem 函数
// function setRem() {
//     // 320 默认大小16px; 320px = 20rem ;每个元素px基础上/16
//     let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth;
//     //得到html的Dom元素
//     let htmlDom = document.getElementsByTagName('html')[0];
//     //设置根元素字体大小
//     htmlDom.style.fontSize = htmlWidth / 20 + 'px';
// }
// // 初始化
// setRem();
// // 改变窗口大小时重新设置 rem
// window.onresize = function() {
//     setRem()
// }