<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <div v-if="isPcShow">
      <div id="toTop">
        <el-row type="flex" class="row-bg" justify="center" id="navMenuId">
          <el-col :span="3" style="">
            <div class="grid-content bg-purple">
              <div class="logoDiv">
                <div class="imgDiv">
                  <img
                    src="./assets/icons/LOGO.png"
                    alt=""
                    @click="optionListClick"
                  />
                </div>
                <!-- <div class="titleDiv">
                <div class="nameCn">新疆维吾尔自治区人民医院</div>
                <div class="nameEg">
                  People's Hopital ofXinjiang Uygur Autonomous Region
                </div>
              </div> -->
              </div>
            </div>
          </el-col>
          <el-col :span="15">
            <div class="oneMenus">
              <el-menu
                router
                :default-active="this.$router.path"
                class="el-menu-demo"
                mode="horizontal"
                @select="handleSelect"
              >
                <template v-for="(item, index) in menuList">
                  <el-menu-item
                    class="submenuUlOne"
                    :key="index"
                    :index="String(item.idx)"
                    v-if="item.attributes.children.data.length < 1"
                    @click.native="pageLestClick(item)"
                    >{{ item.attributes.title }}</el-menu-item
                  >
                  <el-submenu
                    :key="index"
                    :index="String(item.idx)"
                    class="submenuUl"
                    v-if="item.attributes.children.data.length > 0"
                  >
                    <template slot="title">{{
                      item.attributes.title
                    }}</template>
                    <template
                      v-for="(ite, ind) in item.attributes.children.data"
                    >
                        <el-menu-item
                          class="submenuUlThe"
                          :index="item.idx + '-' + ite.idx"
                          :key="ind"
                          @click.native="pageLestSecClick(item, ite)"
                          >{{ ite.attributes.title }}</el-menu-item
                        >
                    </template>
                  </el-submenu>
                </template>
                <!-- <el-submenu
                  v-for="(item, index) in menuList"
                  :key="index"
                  :index="String(item.idx)"
                  class="submenuUl"
                  @click.native="pageLestClick(item)"
                >
                  <template slot="title">{{ item.attributes.title }}</template>
                  <template v-for="(ite, ind) in item.attributes.children.data">
                    <template v-if="ite.attributes.children.data.length !== 0">
                      <el-submenu
                        class="submenuUlThe"
                        :index="item.idx + '-' + ite.idx"
                        :key="ind"
                      >
                        <template slot="title">{{
                          ite.attributes.title
                        }}</template>
                        <el-menu-item
                          v-for="(itee, idxx) in ite.attributes.children.data"
                          :key="idxx"
                          :index="item.idx + '-' + itee.idx + '-' + ite.idx"
                          @click.native="pageLestthiClick(item, ite, itee)"
                          >{{ itee.attributes.title }}</el-menu-item
                        >
                      </el-submenu>
                    </template>

                    <template v-else>
                      <el-menu-item
                        :index="item.idx + '-' + ite.idx"
                        :key="ind"
                        @click.native="pageLestSecClick(item, ite)"
                        >{{ ite.attributes.title }}</el-menu-item
                      >
                    </template>
                  </template>
                </el-submenu> -->
              </el-menu>
            </div>
          </el-col>
        </el-row>
      </div>
      <!-- 回到顶部侧边 -->
      <div class="backTopCont" v-if="isBackTop">
        <div class="backTop">
          <a href="#toTop">
            <img
              class="backImg"
              src="../src/assets/icons/backtotop.png"
              alt=""
            />
            <div class="text">回到顶部</div>
          </a>
        </div>
        <img class="backLogo" src="../src/assets/icons/logo_one.png" alt="" />
      </div>
      <router-view :key="list" />
    </div>
    <div v-else>
      <div class="mobileView">
        <div class="m-logoDiv" id="navMenuId1">
          <div class="m-imgDiv">
            <img
              src="./assets/icons/LOGO.png"
              alt=""
              @click="optionListClick"
            />
          </div>
          <!-- 面包屑 -->
          <div class="m-menusView">
            <div class="m-mbxView" @click="touchMenusClick">
              <img
                class="m-mbxImg"
                v-if="mMenusShow"
                src="./assets/images/mobile/mbx.png"
                alt=""
              />
              <img
                class="m-mbxImg"
                v-if="!mMenusShow"
                src="./assets/images/mobile/mbxgb.png"
                alt=""
              />
            </div>
            <div class="m-menusView" v-if="!mMenusShow">
              <div class="m-m-listView">
                <div
                  class="m-m-list"
                  v-for="(item, index) in menuList"
                  :key="index"
                  @click="pageLestClickMbx(item)"
                >
                  {{ item.attributes.title }}
                  <div
                    class="m-m-l-xlView"
                    v-if="item.attributes.children.data.length"
                    @click.stop="touchMbxClick(index)"
                  >
                    <img
                      class="img1"
                      v-show="item.showMbx"
                      src="./assets/images/mobile/menusO.png"
                      alt=""
                    />
                    <img
                      class="img2"
                      v-show="!item.showMbx"
                      src="./assets/images/mobile/menusC.png"
                      alt=""
                    />
                    <!-- <img class="img2" :src="!item.attributes.showMbx ? require('./assets/images/mobile/menusC.png') : require('./assets/images/mobile/menusO.png')" alt=""> -->
                  </div>
                  <div
                    v-if="item.attributes.children.data.length && item.showMbx"
                  >
                    <div
                      class="m-m--s-list"
                      v-for="(ite, ind) in item.attributes.children.data"
                      :key="ind"
                      @click="pageLestSecClickMob(item, ite)"
                    >
                      {{ ite.attributes.title }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showDiv">1</div>
        <router-view :key="list" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { config } from "./api/config";
import { IsPC } from "./utils/agentFlag";
export default {
  name: "App",
  components: {},
  computed: {
    list: function () {
      return this.$route.query.list;
    },
  },
  data() {
    return {
      isPcShow: true,
      mMenusShow: true,
      attributes: [],
      isBackTop: false,
      showDiv: false,
      menuList: [
        // {
        //   attributes: {
        //     title: '医院首页',
        //     id: '1',
        //     children: {
        //       data: []
        //     }
        //   }
        // }, {
        //   attributes: {
        //     title: '医疗服务',
        //     id: '2',
        //     children: {
        //       data: []
        //     }
        //   }
        // }, {
        //   attributes: {
        //     title: '医院概况',
        //     id: '3',
        //     children: {
        //       data: []
        //     }
        //   }
        // }, {
        //   attributes: {
        //     title: '健康科普',
        //     id: '4',
        //     children: {
        //       data: []
        //     }
        //   }
        // }, {
        //   attributes: {
        //     title: '专家科室',
        //     id: '5',
        //     children: {
        //       data: []
        //     }
        //   }
        // }, {
        //   attributes: {
        //     title: '信息公开',
        //     id: '6',
        //     children: {
        //       data: []
        //     }
        //   }
        // },
        // {
        //   attributes: {
        //     title: '导师专栏',
        //     id: '7',
        //     children: {
        //       data: []
        //     }
        //   }
        // },
        // {
        //   attributes: {
        //     title: '院庆专区',
        //     id: '8',
        //     children: {
        //       data: []
        //     }
        //   }
        // }
      ],
    };
  },
  mounted() {
    // 吸顶效果
    if (this.isPcShow) {
      window.addEventListener("scroll", () => {
        let scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        let offsetTop1 = document.querySelector("#navMenuId").offsetTop;
        if (scrollTop > offsetTop1) {
          this.isBackTop = true;
          document.querySelector("#navMenuId").style.position = "fixed";
          document.querySelector("#navMenuId").style.top = "0";
        } else {
          this.isBackTop = false;
          document.querySelector("#navMenuId").style.position = "";
          document.querySelector("#navMenuId").style.top = "";
        }
      });
    } else {
      // console.log("220000");
      var header = document.getElementById("navMenuId1");
      var headerOriginalPos = header.offsetTop; // 获取元素原来的位置

      window.addEventListener("scroll", function () {
        var scrollTop =
          document.documentElement.scrollTop || document.body.scrollTop;
        if (scrollTop >= headerOriginalPos) {
          header.style.position = "fixed";
          header.style.top = "0";
        } else {
          header.style.position = "";
          header.style.top = "";
          header.style.right = "10";
        }
      });
    }
  },
  created() {
    this.getMenuList();
    if (IsPC()) {
      this.isPcShow = true;
    } else {
      this.isPcShow = false;
    }
    // console.log("window.location.href", IsPC());
  },
  methods: {
    // 移动端面包屑开关
    touchMenusClick() {
      this.mMenusShow = !this.mMenusShow;
    },
    touchMbxClick(e) {
      // console.log("eeeeeee", this.menuList[e]);
      this.menuList[e].showMbx = !this.menuList[e].showMbx;
      this.showDiv = true;
      this.showDiv = false;
      // this.$nextTick(()=>{

      // })
      // console.log(this.menuList, "-----");
      // this.menuList.forEach(item => {
      //   console.log('item', item)
      //   if(item.attributes.title=== e.attributes.title){
      //     this.$nextTick(()=>{
      //       item.attributes.showMbx = !item.attributes.showMbx
      //     })
      //   }
      // })
    },
    handleSelect(key, keyPath) {
      this.activeIndex = keyPath[0];
    },
    // 获取菜单
    getMenuList() {
      axios
        .get(config().urlServe + '/api/menus?populate=*&nested=""')
        .then((res) => {
          if (res.status === 200) {
            // console.log('返回菜单' ,res.data.data[0].attributes.items.data);
            // if(res.data || res.data.data[0].attributes.items){
            //   this.menuList = res.data.data[0].attributes.items.data;
            // }
            this.menuList = res.data.data[0].attributes.items.data;
            this.menuList.forEach((item, i) => {
              item.showMbx = false;
              // console.log("菜单列表", item.attributes);
              item.idx = i + 1;
              if (item.attributes.items) {
                item.attributes.items.data.forEach((ite, idx) => {
                  ite.idx = idx + 1;
                  // ite.attributes.children.data.forEach((itee, idxx) => {
                  //   itee.idx = idxx + 1;
                  // });
                });
              }
            });
            // console.log("菜单", this.menuList);
            // 缓存菜单
            sessionStorage.setItem(
              "allMenuList",
              JSON.stringify(this.menuList)
            );
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    // 进入列表PC
    pageLestClick(e) {
      // this.$message('功能开发中，敬请期待…');
      // 判断是否为首页
      if (e.idx === 1) {
        this.$router.push({
          path: "/index",
        });
      } else {
        this.$router.push({
          path: "/optionList",
          query: {
            title: e.attributes.title,
            list: e.attributes.title,
            active: 1,
          },
        });
      }
    },
    // 进入列表移动端
    pageLestClickMbx(e) {
      // 判断是否为首页
      if (e.idx === 1) {
        this.$router.push({
          path: "/index",
        });
        this.mMenusShow = true;
      } else {
        if (!e.attributes.children.data.length) {
          this.$router.push({
            path: "/optionListMob",
            query: {
              title: e.attributes.title,
              list: e.attributes.title,
              active: 1,
            },
          });
          this.mMenusShow = true;
        } else {
          this.mMenusShow = false;
        }
      }
    },
    pageLestSecClickMob(erm, er) {
      this.$router.push({
        path: "/optionListMob",
        query: {
          title: erm.attributes.title,
          list: er.attributes.title,
          active: 2,
          id: erm.id,
        },
      });
      this.mMenusShow = true;
    },
    // 首页
    optionListClick() {
      this.$router.push({
        path: "/index",
      });
    },
    pageLestSecClick(erm, er) {
      this.$router.push({
        path: "/optionList",
        query: {
          title: erm.attributes.title,
          list: er.attributes.title,
          active: 2,
          id: erm.id,
        },
      });
    },
  },
};
</script>
<style>
/* .el-menu--horizontal>.el-menu-item {
  height: 5px !important;
  line-height: 5px !important;
  font-size: 6px;
  background: red;
}

.el-submenu__title {
  height: 5px !important;
  line-height: 5px !important;
  font-size: 6px !important;
}

.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title {
  height: 8px !important;
  line-height: 8px !important;
  font-size: 5px !important;
  border-bottom: 1px solid #333;
}

.el-menu--popup {
  padding: 1px 0px;
}

.el-menu--horizontal>.el-submenu .el-submenu__icon-arrow {
  width: 1px !important;
  height: 1px !important;
  margin-left: 1px !important;
} */
.el-menu--horizontal>.el-menu-item{
  background: #ffffff !important;
  height: 10px !important;
  line-height: 10px !important;
  font-size: 5px !important;
  text-align: center !important;
  color: #089291 !important;
}

.el-menu--horizontal {
  margin-top: 2px !important;
}
.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title {
  /* background: #7bacac !important;
  height: 10px !important;
  line-height: 10px !important;
  font-size: 5px !important;
  text-align: center;
  color: #fff; */
}

.el-menu--horizontal .el-menu .el-submenu__title {
  font-size: 5px !important;
  text-align: center;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  color: #fff !important;
}

.el-select-dropdown__item.selected {
  color: #fff !important;
}

.el-menu--horizontal > .el-submenu .el-submenu__title {
  height: 10px !important;
  line-height: 9px !important;
  font-size: 6px;
  text-align: center;
  margin-bottom: 5px;
}

.el-icon-arrow-down:before {
  content: "" !important;
}

.el-menu::after,
.el-menu::before {
  display: none !important;
}

.el-menu--horizontal > .el-menu-item {
  color: #089291 !important;
  font-size: 6px !important;
}

.el-menu--horizontal > .el-menu-item:hover {
  background: #089291 !important;
  /* border: 1px solid #fff; */
  box-sizing: border-box;
  /* color: #fff; */
  border-radius: 2px 2px 0 0;
  font-size: 6px !important;
}
.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: 1px solid #089291 !important;
  border-radius: 0px 0px 1px 1px !important;
}

.el-submenu__title {
  color: #089291 !important;
  font-size: 6px !important;
  padding: 1px 4px !important;
  text-align: center;
}

.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .submenuUlItem .el-submenu__title {
  background: #089291 !important;
  color: #fff !important;
  font-size: 5px !important;
  padding: 0px 3px !important;
  text-align: center;
}

.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title {
  background: #089291 !important;
  color: #fff !important;
  font-size: 5px !important;
  padding: 0px 3px !important;
  text-align: center;
}

.submenuUlIdxAct {
  /* background: #e1662a; */
  background: #089291;
  color: #fff;
  border-radius: 2px 2px 0 0;
  border: 1px solid #fff;
  font-size: 5px;
}

.submenuUlIdx {
  background: #089291;
  color: #fff;
  font-size: 6px;
}

.submenuUlItem {
  background: #089291 !important;
  color: #fff !important;
  font-size: 6px !important;
}

.is-opened {
  color: #fff !important;
  background: #089291 !important;
  font-size: 6px !important;
  /* border: 1px solid #fff !important; */
  box-sizing: border-box !important;
  border-radius: 2px 2px 0 0 !important;
}

.is-opened .el-submenu__title {
  color: #fff !important;
  font-size: 5px !important;
  /* padding: 0 !important; */
  text-align: center;
}

.el-menu--horizontal .el-menu .el-submenu__title {
  background: #089291 !important;
  color: #fff !important;
  font-size: 5px !important;
  /* padding: 0 !important; */
  text-align: center;
}

.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .submenuUlItem .el-submenu__title {
  background: #089291 !important;
  color: #fff !important;
  font-size: 5px !important;
  /* padding: 0px 3px !important; */
  text-align: center;
}

.el-menu--horizontal .el-menu .el-menu-item,
.el-menu--horizontal .el-menu .el-submenu__title {
  background: #089291 !important;
  color: #fff !important;
  font-size: 5px !important;
  /* padding: 0px 3px !important; */
  text-align: center;
}

.el-submenu__title:hover {
  background: none !important;
  text-align: center !important;
}

.el-menu--horizontal > .el-menu-item.is-active {
  color: #fff !important;
  background: #089291 !important;
  font-size: 5px !important;
  /* border: 1px solid #fff; */
  box-sizing: border-box;
  border-radius: 2px 2px 0 0;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  color: #fff !important;
  font-size: 5px !important;
}
.submenuUlOne .el-menu--horizontal .el-menu-item:not(.is-disabled) {
  color: #089291 !important;
  font-size: 5px !important;
}
.submenuUlOne{
  width: 35px !important;
  padding: 0px 2px !important;
}
.submenuUlThe{
  color: #fff !important;
  font-size: 5px !important;
  height: 10px !important;
  line-height: 9px !important;
  width: 50px !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.submenuUl .el-submenu__title{
  width: 35px !important;
  padding: 0px 2px !important;
}
.el-menu--horizontal>.el-menu-item.is-active{
  border-bottom: 1px solid #089291 !important;
  /* border-radius: 0px 0px 1px 1px !important; */
}

.el-menu--horizontal > .el-submenu {
  /* width: 12.5%; */
}

.el-menu--horizontal > .el-submenu .el-submenu__icon-arrow {
  margin-left: 0 !important;
  margin-top: 0 !important;
  top: 93% !important;
  font-size: 4px !important;
}

.el-submenu__icon-arrow {
  top: 93% !important;
  font-size: 4px !important;
}

.el-menu--popup {
  background: #089291 !important;
  padding: 0 !important;
  margin: 0 !important;
  min-width: 0px !important;
  font-size: 5px !important;
  box-shadow: 0 5px 5px 0 rgb(0, 0, 0);
}
/* .el-menu--popup {
    z-index: 100;
    min-width: 12.5rem;
    border: none;
    padding: 0.3125rem 0;
    border-radius: 0.125rem;
    -webkit-box-shadow: 0 0.125rem 0.75rem 0 rgba(0,0,0,.1);
    
} */

/* .submenuUlThe>.el-menu--horizontal>.el-menu--popup {
  min-width: 40px !important;
  background: red !important;
} */
.el-menu.el-menu--horizontal {
  border-bottom: none !important;
}

.el-menu--popup .submenuUlThe .el-submenu__title {
  /* color: #a73c3c !important; */
  color: #fff !important;
  font-size: 5px !important;
}
.el-submenu .el-menu-item {
  min-width: 50px !important;
  /* width: 100px !important; */
  padding: 0px 2px !important;
}
</style>

<style lang="less" scoped>
#navMenuId {
  width: 100%;
  background: #fff;
  z-index: 2000;
  box-shadow: 0 0 2px 1px #aaa;
}
// #navMenuId1 {
//   width: 100%;
//   padding: 10;
//   background: #fff;

//   // box-shadow: 0 0 2px 1px #aaa;
// }
.backTopCont {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 1000;
  width: 25px;
  height: 60px;
  background: #fff;
  border-radius: 10px 10px 40px 40px;
  box-shadow: 0px 0px 3px 2px #eee;
  overflow: hidden;
  .backTop {
    width: 100%;
    background: #fff;
    text-align: center;
    padding: 2px 0 2px;
    img {
      width: 80%;
    }
    .text {
      color: #666;
      font-size: 5px;
    }
  }
  .backLogo {
    width: 100%;
    margin-top: 0px;
  }
}
.logoDiv {
  // display: flex;
}

.imgDiv {
  width: 100%;
}

.imgDiv img {
  width: 100%;
  margin-top: 1px;
}

.logoInfo {
  margin-left: 10%;
}

.titleDiv {
  position: relative;
  margin-left: 2px;
  top: 3.5px;
}

.nameCn {
  font-size: 6px;
  font-weight: bold;
  color: #333;
}

.nameEg {
  font-size: 3.5px;
  margin-top: 1px;
}

.oneMenus {
  display: flex;
  justify-content: space-between;
  position: relative;
  height: 10px;
  width: 100%;
  line-height: 10px;
  font-size: 6px;
  top: 3px;
  color: #555;
  /* background-color: #999; */
}

.oneMenusList {
  width: 12.8%;
  height: 10px;
  line-height: 10px;
  text-align: center;
  /* border: 1px solid #999; */
  box-sizing: border-box;
  /* font-weight: 550; */
}

.oneMenusList h5 {
  margin: 0;
}

.twoMenus {
}

.menuContainer {
  height: 60px;
  background: #00804b;
  opacity: 0.8;
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
  position: relative;
  z-index: 50;
  padding: 0 10%;

  .menuList {
    width: 11%;
    position: relative;

    .firstMenu {
      color: #fff;
      font-size: 24px;
      height: 60px;
      line-height: 60px;
    }

    .firstMenuAct {
      background-color: #dd5b25;
      height: 65px;
      line-height: 65px;
      font-size: 24px;
      color: #fff;
      font-weight: bold;
      margin-top: -8px;
      border-radius: 10px 10px 0px 0px;
      border: 2px solid #fff;
    }

    .secondMenu {
      position: absolute;
      width: 100%;
      background-color: #dd5b25;

      .secondList {
        width: calc(100% - 20px);
        padding: 0px 10px;
        font-size: 16px;
        margin-bottom: 8px;
        color: #fff;
        text-align: left;
        height: 30px;
        line-height: 30px;
      }

      .secondListAct {
        width: calc(100% - 20px);
        padding: 0px 10px;
        color: #fff;
        font-size: 6px;
        margin-bottom: 2px;
        height: 8px;
        line-height: 8px;
        text-align: left;
        box-shadow: 0px 2px 5px 1px #eee;
      }

      .secondListAct:first-child {
        margin-top: 1px;
      }

      .secondListAct:last-child {
        margin-bottom: 2px;
      }

      .secondList:first-child {
        margin-top: 1px;
      }

      .secondList:last-child {
        margin-bottom: 2px;
      }
    }
  }
}

.el-menu-demo {
  width: 100%;
  height: 9px;
  margin: 0 auto;
  color: #089291;
  display: flex;
  justify-content: space-around;
  margin-top: 1px;
  position: relative;
  z-index: 50;
  margin-top: 1px;
  flex-wrap: nowrap;
}
// 移动端样式
.mobileView {
  width: calc(100% - 10px);
  padding: 3px 5px;
  #navMenuId1 {
    border-bottom: 0.2px solid #089291;
    width: 100%;
    // text-align: center;
    background: #fff;
    position: fixed;
    z-index: 1000;
    img {
      width: 70%;
    }
    // 面包屑菜单
    .m-menusView {
      position: absolute;
      top: 6px;
      right: 6px;
      width: 20px;
      height: 20px;
      // box-shadow: 0px 0px 1px 0.2px #089291;
      .m-mbxView {
        position: relative;
        z-index: 1100;
        .m-mbxImg {
          position: absolute;
          top: 5px;
          left: 20%;
          width: 60%;
        }
      }
      .m-menusView {
        position: relative;
        top: 16px;
        .m-m-listView {
          padding: 10px 10px;
          position: absolute;
          top: 0px;
          right: 0px;
          width: 120px;
          text-align: center;
          background: rgba(255, 255, 255, 0.9);
          box-shadow: 0px 0px 1px 0.2px #089291;
          max-height: 190px;
          overflow-y: auto;
          .m-m-list {
            width: 100%;
            line-height: 12px;
            margin-bottom: 1px;
            position: relative;
            font-size: 6.5px;
            color: #089291;
            // background: #089291;
            border-bottom: 0.1px solid rgba(37, 168, 168, 0.4);
            .m-m-l-xlView {
              position: absolute;
              right: 20px;
              top: 0px;
              .img1 {
                width: 5px;
              }
              .img2 {
                width: 8px;
                margin-top: 2px;
              }
            }
            .m-m--s-list {
              color: #888;
              font-size: 5.5px;
            }
          }
        }
        // width: 100px;
      }
    }
  }
}
</style>