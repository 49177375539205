<!--
 * @Author: 1697482796@qq.com 1697482796@qq.com
 * @Date: 2023-02-08 12:24:15
 * @LastEditors: 1697482796@qq.com 1697482796@qq.com
 * @LastEditTime: 2023-03-31 18:07:23
 * @FilePath: \sdy_webpack1\src\pages\optionList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="optionList">
    <!-- 背景图 -->
    <div class="backgrpund_img"></div>
    <div class="blackDiv"></div>
    <!-- 搜索框 -->
    <div class="searchValue">
      <el-row type="flex" class="row-bg" justify="center">
        <el-col :span="5" class="selectSeaView">
          <el-select
            v-model="selectValue"
            placeholder="请选择"
            @change="selectChange"
          >
            <el-option
              v-for="item in searchOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="13">
          <div class="searchIpt">
            <el-input placeholder="请输入内容" v-model="searchValue"></el-input>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="searchBtn">
            <el-button
              type="primary"
              icon="el-icon-search"
              @click="toSearchPage"
              >立即搜索</el-button
            >
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 内容 -->
    <div class="optionContainer">
      <div class="blackDiv1">搜索结果</div>
      <!-- 新闻 默认 -->
      <div class="" v-show="newNtabShow">
        <div class="opTabsView">
          <!-- 内容 -->
          <div class="tabsContent">
            <!-- 列表 -->
            <div class="newdetailView">
              <div class="newsDetailListValue" v-if="searchList.length > 0">
                <div
                  class="newsDetailList"
                  v-for="(item, index) in searchList"
                  :key="index"
                  @click="detailInfoClick(item, 'news')"
                >
                  <div class="newTimer">
                    <img src="../../src/assets/icons/虚线.png" alt="" />
                    <div class="newTimerD">
                      <span>{{ item.dayr }}</span>
                      <span>{{ item.timer }}</span>
                    </div>
                  </div>
                  <div class="newText">
                    <div class="newTextTitle">
                      {{ item.articleTitle }}
                    </div>
                    <div class="newTextDesc">
                      {{ item.contStr }}
                    </div>
                  </div>
                </div>
                <div class="newPageView">
                  <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="curNewPage"
                    :page-size="pageSize"
                    layout="total, prev, pager, next"
                    :total="newTotal"
                  >
                  </el-pagination>
                </div>
              </div>
              <div class="listEmpty newsDetailListValue" v-else>
                <img src="../assets/icons/nodata.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 专家 -->
      <div class="expertView" v-show="exportShow">
        <div class="infoContent">
          <div class="zjIntInfoLists" v-if="searchList.length > 0">
            <div
              class="infoFlex"
              v-for="(item, index) in searchList"
              :key="index"
              @click="expertDetailClick(item)"
            >
              <div class="zjList">
                <div class="imgDiv">
                  <img v-if="item.photo" :src="imgUrl + item.photo.url" alt />
                  <div class="zjName">{{ item.doctorName }}</div>
                </div>
                <div class="infoRight">
                  <div class="txDiv titleDiv">
                    <span class="title txTitle">医生职称</span>
                    <div class="text txText">
                      {{ item.doctorTitle }}
                    </div>
                  </div>
                  <div class="yjDiv titleDiv">
                    <span class="title yjTitle">主要研究</span>
                    <div class="text yjText">
                      {{ item.expertise }}
                    </div>
                  </div>
                  <div class="timerDiv">
                    <span class="title tmTitle">出诊时间</span>
                    <span class="text timeText">{{ item.visitTime }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="newPageView">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="curNewPage"
                :page-size="pageSize"
                layout="total, prev, pager, next"
                :total="newTotal"
              >
              </el-pagination>
            </div>
          </div>
          <div class="listEmpty" v-else>
            <img src="../assets/icons/nodata.png" alt />
          </div>
        </div>
      </div>
      <!-- 科室 -->
      <div class="" v-show="deptShow">
        <div class="opTabsView">
          <!-- 列表 -->
          <div class="deptdetailView">
            <div
              class="deptList"
              v-for="(ite, ind) in searchList"
              :key="ind"
              @click="deptdetailClick(ite)"
            >
              {{ ite.name }}
              <img src="../assets/icons/rigth-ar.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <footerMob></footerMob>
  </div>
</template>

<script>
import axios from "axios";
// import Silder from "@/components/silder.vue";
import footerMob from "@/components/footerMob.vue";
import { config } from "../api/config";
export default {
  name: "searchPageMob",
  components: {
    footerMob,
    // Silder,
  },
  data() {
    return {
      allMenus: [],
      curMenuObj: {},
      curTitle: "",
      newNtabShow: false,
      deptShow: true,
      exportShow: false,
      tutorShow: false,
      searchValue: "",
      activeTabName: "",
      activeDptName: "",
      oneList: [],
      newsSwiperList: [],
      newsDetailList: [],
      expTabActive1: true,
      expTabActive2: false,
      expTabActive3: false,
      expTabActive4: false,
      expertList: [],
      deptMenusList: [],
      deptDetailList: [],
      // 分页
      newTotal: 0,
      curNewPage: 1,
      pageSize: 10,
      newZbTitle: "",
      searchOptions: [
        {
          label: "新闻",
          value: "article",
        },
        {
          label: "医生",
          value: "doctor",
        },
        {
          label: "科室",
          value: "department",
        },
      ],
      selectValue: "article",
      searchList: [],
    };
  },
  watch: {},
  created() {
    this.imgUrl = config().imgserve;
    this.searchValue = this.$route.query.keyword;
    this.selectValue = this.$route.query.value;
    if (this.$route.query.value === "article") {
      this.newNtabShow = true;
      this.exportShow = false;
      this.deptShow = false;
    }
    if (this.$route.query.value === "doctor") {
      this.newNtabShow = false;
      this.exportShow = true;
      this.deptShow = false;
    }
    if (this.$route.query.value === "department") {
      this.newNtabShow = false;
      this.exportShow = false;
      this.deptShow = true;
    }
    this.getSearchList(
      this.selectValue,
      this.searchValue,
      this.pageSize,
      this.curNewPage
    );
  },
  methods: {
    // 按规定长度截取数组
    grouping(arr, len) {
      let col = Math.ceil(arr.length / len);
      let data = [];
      let start = 0;
      while (start < col) {
        let temp = arr.slice(start * len, (start + 1) * len);
        data[start] = temp;
        start++;
      }
      return data;
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.curNewPage = val;
      this.getSearchList(
        this.selectValue,
        this.searchValue,
        this.pageSize,
        val
      );
    },
    selectChange(e) {
      this.searchValue = "";
      this.searchList = [];
      // console.log("看看选择", e, this.selectValue);
    },
    // 搜索
    getSearchList(uid, keyword, size, page) {
      this.searchList = [];
      const instance = axios.create({
        baseURL: config().urlServe,
        headers: {
          Authorization:
            "Bearer 8dda6ef50da88243f81f4dd77769992c8c711fec5626355cf03a6b824d5af7a5",
        },
      });
      instance
        .get(config().urlServe + "/indexes/" + uid + "/search", {
          params: {
            q: keyword,
            page: page,
            hitsPerPage: size,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            let list = res.data.hits;
            this.newTotal = 0;
            this.newTotal = res.data.totalHits;
            if (this.selectValue === "article") {
              list.forEach((item) => {
                const tempDivElement = document.createElement("div");
                tempDivElement.innerHTML = item.content;
                const planText =
                  tempDivElement.textContent || tempDivElement.innerText || "";
                item.contStr = planText.substring(0, 100);
                item.isActive = false;
                item.timer = item.updatedAt.slice(0, 7);
                item.monthr = item.updatedAt.slice(5, 7);
                item.dayr = item.updatedAt.slice(8, 10);
              });
              this.searchList = list;
            } else {
              this.searchList = list;
            }
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    toSearchPage() {
      this.curNewPage = 1;
      if (this.selectValue === "article") {
        this.newNtabShow = true;
        this.exportShow = false;
        this.deptShow = false;
      }
      if (this.selectValue === "doctor") {
        this.newNtabShow = false;
        this.exportShow = true;
        this.deptShow = false;
      }
      if (this.selectValue === "department") {
        this.newNtabShow = false;
        this.exportShow = false;
        this.deptShow = true;
      }
      this.getSearchList(
        this.selectValue,
        this.searchValue,
        this.pageSize,
        this.curNewPage
      );
    },
    // 新闻详情
    detailInfoClick(e, type) {
      // 判断是否跳转外部链接
      if (e.link) {
        window.location.href = e.link
      } else {
        this.$router.push({
          path: "/deptDetailInfoMob",
          query: {
            title: "",
            list: "详情",
            type: type,
            id: e.id,
          },
        });
      }
    },
    // 专家详情
    expertDetailClick(e) {
      this.$router.push({
        path: "/expertDetailMob",
        query: {
          list: e.doctorName,
          id: e.id,
        },
      });
    },
    // 科室详情
    deptdetailClick(e) {
      this.$router.push({
        path: "/deptDetailMob",
        query: {
          list: e.name,
          id: e.id,
        },
      });
    },
    // 返回首页
    goIndex() {
      this.$router.push({
        path: "/index",
      });
    },
  },
};
</script>
<style>
.el-select-dropdown__item {
  padding: 0 5px !important;
  font-size: 5px !important;
  height: 8px !important;
  line-height: 8px !important;
  border-radius: 2px !important;
  text-align: center !important;
}
.el-select-dropdown__list {
  padding: 5px 0px !important;
}
.el-select-dropdown {
  min-width: 35px !important;
  border: 0.1px solid #eee !important;
}
.el-popper[x-placement^="bottom"] {
  margin-top: 2px !important;
}
.el-select-dropdown__item.selected {
  color: #089291 !important;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
// @media screen and (max-width: 5000px) {
//   .optionContainer {
//     width: 80%;
//   }
// }
// @media screen and (max-width: 1500px) {
//   .optionContainer {
//     width: 90%;
//   }
// }
// @media screen and (max-width: 900px) {
//   .optionContainer {
//     width: 98%;
//   }
// }
.selectSeaView {
  position: relative;
  top: 5px;
  /deep/ .el-input--suffix .el-input__inner {
    padding-right: 0px !important;
  }
  /deep/ .el-input__inner {
    padding: 0px 5px !important;
    height: 14px !important;
    line-height: 14px !important;
    border-radius: 2px !important;
    border: 0.1px solid #eee !important;
    font-size: 6px !important;
    color: #089291 !important;
  }
  /deep/ .el-select .el-input .el-select__caret {
    line-height: 8px !important;
    font-size: 8px !important;
  }
  /deep/ .el-input__icon {
    width: 10px !important;
  }
}
.optionList {
  width: 100%;
  position: relative;
  margin-top: 30px;
  .listEmpty {
    width: 100%;
    text-align: center;
    padding-bottom: 20px;
    // padding-top: 20px;
    img {
      width: 40px !important;
      margin-top: 20px !important;
    }
  }

  .backgrpund_img {
    position: absolute;
    background: #dff1f5;
    top: 0px;
    height: 50px;
    width: 100%;
    background-image: url("../assets/images/h_xj_bg.png");
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .blackDiv {
    height: 5px;
  }
  .blackDiv1 {
    padding: 2px 5px 5px;
    font-size: 6px;
    color: #0b312b;
  }

  .optionContainer {
    width: calc(100% - 10px);
    padding: 5px;
    margin: 0 auto;
    margin-top: 5px;
    box-shadow: 2px 0px 10px 1px rgba(187, 188, 189, 0.4);

    .rightCont {
      width: 100%;
      border-radius: 10px;
      background: #fff;
      box-shadow: 0px 0px 3px 1px #eee;

      .funcTitle {
        position: relative;
        width: 100%;
        padding-bottom: 11px;
        color: #089291;
        border-bottom: 1px solid #eee;
        margin-bottom: 20px;

        img {
          position: absolute;
          top: 10px;
          left: 20px;
          width: 30px;
        }
      }
    }

    .optionTitle {
      font-size: 10px;
      font-weight: bold;
      letter-spacing: 1px;
      color: #0b312b;
      text-align: center;
    }

    .optionDesc {
      font-size: 6px;
      color: #e7e7e7;
      text-align: center;
      padding-bottom: 5px;
      border-bottom: 0.1px solid #eee;
    }

    // 组件样式修改
    .opTabsView {
      /deep/ .el-tabs__nav-scroll {
        display: flex !important;
        justify-content: center !important;
      }

      /deep/ .el-tabs__item {
        height: 13px !important;
        line-height: 13px !important;
        font-size: 6px !important;
        padding: 0 5px !important;
        max-width: 50px !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      /deep/ .el-tabs__nav {
        margin: 0 auto !important;
        border: 0.2px solid #eee;
      }

      /deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
        color: #fff !important;
        background-color: #089291 !important;
      }

      /deep/ .el-tabs--border-card > .el-tabs__header {
        background-color: #fff !important;
      }

      /deep/ .el-tabs--border-card > .el-tabs__header {
        border: none;
      }

      /deep/ .el-tabs--border-card {
        border: none;
      }

      /deep/ .el-tabs__nav-next {
        line-height: 13px;
        font-size: 6px;
        color: #089291;
      }

      /deep/ .el-tabs__nav-prev {
        line-height: 13px;
        font-size: 6px;
        color: #089291;
      }

      /deep/.el-tabs__nav-wrap.is-scrollable {
        padding: 0px 10px;
      }

      /deep/ .el-tabs__content {
        padding-top: 6px !important;
      }

      /deep/
        .el-tabs--border-card
        > .el-tabs__header
        .el-tabs__item:not(.is-disabled):hover {
        color: #089291 !important;
      }
      /deep/
        .el-tabs--border-card
        > .el-tabs__header
        .el-tabs__item.is-active:hover {
        color: #fff !important;
      }
    }
    // 科室中心
    .deptdetailView {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-top: 10px;

      .deptList {
        width: 36%;
        height: 14px;
        line-height: 14px;
        border-radius: 10px;
        border: 0.1px solid #089291;
        padding: 0 5px;
        margin-bottom: 10px;
        color: #089291;
        font-size: 4.5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 10px;
        position: relative;

        img {
          width: 6px;
          position: absolute;
          top: 4px;
          right: 2px;
        }
      }
    }

    .tabsContent {
      width: 100%;
      // margin: 0 auto;
      // height: 100px;
      // background: #74a5a5;

      // 新闻
      .newdetailView {
        width: 100%;
        // height: 230px;
        font-size: 5px;
        text-align: center;
        line-height: 100%;
      }

      .newsDetailListValue {
        width: 100%;
        // height: 65px;
        background: #fff;
        // max-height: 135px;
        // overflow: hidden;
        // overflow-y: auto;

        .newsDetailList {
          width: 100%;
          padding: 5px 0px;
          text-align: left;
          display: flex;
          justify-content: space-between;

          .newTimer {
            width: 28px;
            height: 18px;
            border: 0.1px solid #eee;
            border-radius: 1px;
            box-sizing: border-box;
            display: flex;

            img {
              height: 15px;
              position: relative;
              left: 1px;
              top: 1.5px;
            }

            .newTimerD {
              margin-top: 3px;
              color: #089291;

              span {
                display: block;
                margin-left: 1px;
                font-size: 6px;
                font-weight: bold;
              }

              span:last-child {
                font-size: 4px;
                font-weight: normal;
                margin-top: 2px;
              }
            }
          }

          .newText {
            width: calc(100% - 32px);
            padding-bottom: 5px;
            border-bottom: 0.1px solid #eee;

            .newTextTitle {
              color: #333;
              font-size: 5px;
              font-weight: bold;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .newTextDesc {
              font-size: 4px;
              height: 12px;
              line-height: 6px;
              margin-top: 4px;
              color: #888;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
          }
        }
      }

      // .newsDetailListValue::-webkit-scrollbar {
      //   width: 1px;
      // }

      // .newsDetailListValue::-webkit-scrollbar-thumb {
      //   background-color: #089291;
      // }
    }

    // 专家
    .expertView {
      width: 100%;
      // background: #1b7f96;
      padding-bottom: 10px;

      // margin-bottom: 100px;
      .expertTitle {
        width: 100%;
        text-align: center;
        font-size: 10px;
        font-weight: bold;
        margin-top: 5px;
        letter-spacing: 1px;
      }

      .expertTitle span:first-child {
        color: #020202;
      }

      .expertTitle span:last-child {
        color: #049d98;
      }

      .expertTitleDesc {
        width: 100%;
        text-align: center;
        font-size: 4px;
        color: #aaa;
        margin-top: 1px;
      }

      .expertTiView {
        width: 280px;
        border: 0.1px solid #dff1f5;
        margin: 10px auto 5px;
        display: flex;
        justify-content: space-between;
        // background: rgba(70, 69, 69, 0.2);
        border-radius: 10px;
        height: 15px;
        line-height: 15px;

        // height: 100px;
        .expertTiList {
          width: 33.3%;
          border-radius: 10px;
          text-align: center;
          font-size: 6px;
          font-weight: bold;
          color: #01312f;
          position: relative;

          img {
            width: 11px;
            position: absolute;
            top: 2.5px;
            margin-right: 2px;
          }

          span {
            margin-left: 12px;
            margin-top: 5px;
          }
        }

        .expertTiListActive {
          width: 33.3%;
          border-radius: 10px;
          text-align: center;
          font-size: 6px;
          font-weight: bold;
          color: #fff;
          background: #049d98;
          position: relative;

          img {
            width: 11px;
            position: absolute;
            top: 2.5px;
            margin-right: 2px;
          }

          span {
            margin-left: 12px;
            margin-top: 5px;
          }
        }
      }

      .infoContent {
        width: 100%;
        padding: 0px;
        // max-height: 245px;
        // overflow: hidden;
        box-shadow: 0px 0px 3px 1px #eee;
      }

      .zjIntInfoLists {
        width: calc(100% - 10px);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 5px;
        // max-height: 230px;
        // overflow: hidden;
        // overflow-y: auto;
        padding: 5px;

        .infoFlex {
          width: 100%;
        }

        .zjList {
          widows: 100%;
          border-radius: 3px;
          box-shadow: 0px 0px 2px 1px #d1d0d0;
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-between;
          margin-bottom: 5px;
          padding: 5px;

          // height: 123px;
          // .imgDiv {
          //       height: 203px;
          //       width: 145px;
          //       img {
          //         height: 203px;
          //         width: 145px;
          //       }
          .imgDiv {
            height: 70px;
            width: 50px;
            // width: 21%;
            border-radius: 3px;
            overflow: hidden;
            position: relative;

            img {
              width: 100%;
            }

            .zjName {
              width: 100%;
              height: 13px;
              line-height: 13px;
              position: absolute;
              bottom: 0px;
              background-color: rgba(39, 41, 41, 0.4);
              text-align: center;
              color: #fff;
              font-size: 6px;
            }
          }

          .infoLeft {
            width: calc(79% - 10px);
            margin-right: 10px;
            position: relative;
          }

          .infoRight {
            width: calc(79% - 10px);
            margin-left: 10px;
            position: relative;
          }

          .timerDiv {
            position: absolute;
            bottom: 0px;
          }

          .title {
            display: inline-block;
            width: 20px;
            text-align: center;
            height: 8px;
            line-height: 8px;
            border-radius: 5px;
            color: #fff;
            font-size: 4px;
          }

          .txTitle {
            background-color: #049d98;
          }

          .yjTitle {
            background-color: #2f75bc;
          }

          .tmTitle {
            background-color: #dd5b25;
          }

          .text {
            color: #5b5b5b;
            line-height: 8px;
            margin-top: 2px;
            font-size: 4.4px;
          }

          .txText {
            margin-bottom: 3px;
            // 多行省略
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }

          .yjText {
            // 多行省略
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
          }
        }
      }

      // .zjIntInfoLists::-webkit-scrollbar {
      //   width: 1px;
      // }

      // .zjIntInfoLists::-webkit-scrollbar-thumb {
      //   background-color: #049d98;
      // }
    }
  }

  .cursorSpan:hover {
    cursor: pointer;
    color: #089291;
  }

  // 搜索框
  .searchValue {
    width: 90%;
    margin: 5px auto;
    border: 0.1px solid #eee;
    border-bottom: 1px solid #089291;
    background: #fff;
    position: relative;
    z-index: 100;
  }
  .listEmpty {
    width: 100%;

    // padding-top: 20px;
    img {
      width: 40px !important;
      margin-top: 20px !important;
    }
  }

  .carouselViewNo {
    height: 120px;

    .noDataView {
      line-height: 100px;
      font-size: 4.5px;
      color: #01312f;
      background: rgba(0, 0, 0, 0.3);
    }
  }
  .newPageView {
    // width: 200px;
    text-align: right;
    overflow-x: auto;
    /deep/ .el-pagination {
      padding: 3px 0px 10px;
      font-size: 5px !important;
      font-weight: normal !important;
    }
    /deep/ .el-pagination span:not([class*="suffix"]) {
      font-size: 5px !important;
      line-height: 8px !important;
      height: 8px !important;
    }
    /deep/ .el-pagination__sizes .el-input .el-input__inner {
      font-size: 5px !important;
    }
    /deep/ .el-input--mini .el-input__inner {
      height: 8px !important;
      line-height: 8px !important;
      font-size: 5px !important;
    }
    /deep/ .el-pagination button {
      height: 8px !important;
      line-height: 8px !important;
      font-size: 5px !important;
    }
    /deep/ .el-pager li {
      height: 8px !important;
      line-height: 8px !important;
      font-size: 5px !important;
    }
    /deep/ .el-pager .more::before {
      height: 8px !important;
      line-height: 8px !important;
    }
    /deep/ .el-pagination .btn-prev .el-icon {
      font-size: 6px !important;
    }
    /deep/ .el-pagination .btn-next .el-icon {
      font-size: 6px !important;
    }
    /deep/ .el-pager li {
      min-width: 8px !important;
    }
    /deep/ .el-pagination span:not([class*="suffix"]) {
      min-width: 10px !important;
    }
    /deep/ .el-pagination__total {
      margin-right: 0px !important;
    }
    /deep/ .el-pagination .btn-prev {
      padding-right: 0px !important;
    }
    /deep/ .el-pagination .btn-next {
      padding-left: 0px !important;
    }
    /deep/ .el-pagination button {
      min-width: 10px !important;
    }
  }
  .searchValue {
    width: calc(100% - 5px);
    margin: 10px auto;
    padding-left: 3px;
    border: 0.1px solid #eee;
    border-bottom: 1px solid #089291;
    .selectSeaView {
      position: relative;
      top: 1px;
    }
    .searchIpt {
      padding: 6px 2px;

      /deep/ .el-input {
        font-size: 0;
      }

      /deep/ .el-input__inner {
        height: 15px !important;
        font-size: 5px !important;
        border: 0.1px solid #eee;
        border-radius: 2px;
      }
    }

    .searchBtn {
      padding: 6px 0px;

      .el-button {
        height: 12px !important;
        line-height: 12px !important;
        font-size: 5px !important;
        padding: 0px 4px !important;
        border-radius: 2px;
        position: relative;
        top: 2px;
      }

      /deep/ .el-button [class*="el-icon-"] + span {
        margin-left: 0px !important;
      }

      .el-button--primary {
        color: #089291;
        border: 0.1px solid #089291;
        background: #fff !important;
        border-radius: 10px;
      }
    }
  }
}
</style>