<!--
 * @Author: 1697482796@qq.com 1697482796@qq.com
 * @Date: 2023-02-08 12:24:15
 * @LastEditors: 1697482796@qq.com 1697482796@qq.com
 * @LastEditTime: 2023-03-31 12:37:49
 * @FilePath: \sdy_webpack1\src\pages\deptDetail.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="deptDetail">
    <!-- 背景图 -->
    <div class="backgrpund_img"></div>
    <div class="positionDiv">
      <div class="positionContent">
        <div class="positionTitle">详情介绍</div>
        <div >您的位置：<span class="cursorSpan" @click="goIndex">医院首页</span
          >&nbsp;&gt;&nbsp;详情介绍
        </div>
      </div>
      <!-- >&nbsp;&gt;&nbsp;所有科室&nbsp;&gt;&nbsp;{{ deptInfo.name }} -->
    </div>
    <!-- 内容 -->
    <div class="optionContainer">
      <!-- 科室内容 -->
      <div class="deptZJContainer">
        <div class="deptContent">
          <!-- 新闻 -->
          <div v-if="type === 'news'">
            <div class="descTitle">{{ curObj.articleTitle }}</div>
            <div  class="descCont" v-html="curObj.content"></div>
          </div>
          <!-- 科室 -->
          <div v-if="type === 'dept'">
            <div class="descTitle">{{ curObj.name }}</div>
            <div class="descCont" v-html="curObj.departmentDetail"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <Footer></Footer>
  </div>
</template>

<script>
import axios from "axios";
import Footer from "@/components/footer.vue";
import { config } from "../api/config";
import { IsPC } from "@/utils/agentFlag";
export default {
  name: "deptDetailInfo",
  components: {
    Footer,
  },
  data() {
    return {
      type: '',
      imgUrl: "",
      dialogVisible: false,
      curObj: {},
      deptId: ''
    };
  },
  created() {
    this.type = this.$route.query.type
    this.deptId = this.$route.query.id
    if (!IsPC()) {
      this.$router.push({
        path: "/deptDetailInfoMob",
        query: {
          list: this.$route.query.name,
          type: this.$route.query.type,
          id: this.deptId
        }
      });
      return
    }
    this.imgUrl = config().imgserve
    this.curObj = {}
    if(this.$route.query.type === 'news'){
      this.getcurNewsDetail()
    }else{
      this.getcurdeptDetail()
    }
    
  },
  methods: {
    // 获取新闻详情
    getcurNewsDetail() {
      axios
        .get(config().urlServe + "/api/articles/" + this.deptId)
        .then(res => {
          if (res.status === 200) {
            this.curObj = res.data.data.attributes;
            document.title = '新疆维吾尔自治区人民医院-' + this.curObj.articleTitle
          }
        })
        .catch(err => {
          alert(err);
        });
    },
    // 获取科室详情
    getcurdeptDetail(){
      axios
        .get(
          config().urlServe +
            "/api/departments/"+this.deptId
        )
        .then((res) => {
          if (res.status === 200) {
            this.curObj = res.data.data.attributes
            document.title = '新疆维吾尔自治区人民医院-' + this.curObj.name
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    // 返回首页
    goIndex() {
      this.$router.push({
        path: "/index",
      });
    },
  },
};
</script>

<style lang="less" scoped>
h1 {
  margin: 0px;
  padding: 0px;
}
.deptDetail {
  width: 100%;
  position: relative;
  .backgrpund_img {
    height: 140px;
    width: 100%;
    background-image: url("../assets/images/detail-t-bg.jpg");
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    box-shadow: 0px 0px 1px 0.3px #1E4743;
  }
  .blackDiv {
    height: 10px;
  }
  .listMore {
    position: absolute;
    z-index: 100;
    color: #00804b;
    font-size: 5px;
    width: 15px;
    height: 15px;
    text-align: center;
    line-height: 28px;
    border: 2px solid #00804b;
    border-radius: 15px;
    right: 15px;
    top: 5px;
    img {
      width: 20px;
      position: relative;
      top: 4px;
      left: 2px;
    }
  }
  .positionDiv {
    font-size: 4px;
    height: 20px;
    width: 100%;
    background: #11969E;
    color: #DFF1F5;
    .positionContent{
      width: 63%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      height: 20px;
      line-height: 20px;
    }
    .positionTitle{
      font-size: 6px;
      font-weight: bold;
    }
  }
  .optionContainer {
    width: 65%;
    margin: 0 auto;
    position: relative;
    text-align: left;
    .cursorSpan:hover {
      cursor: pointer;
      color: #2d8e64;
    }
    
    .deptName {
      font-size: 8px;
      margin: 7px 0;
    }
    .deptZJContainer {
      width: calc(100% - 20px);
      background: #DFF1F5;
      border-radius: 1px;
      padding: 10px;
      // height: 200px;
      .deptContent{
        line-height: 7px;
        margin-top: 20px;
        .descTitle{
          width: 75%;
          margin: 0 auto;
          font-size: 7px;
          text-align: center;
          font-weight: bold;
          line-height: 10px;
        }
        .descCont{
          text-indent: 2ch;
          font-size: 5.5px !important;
          color: #1E4743 !important;
          line-height: 10px !important
        }
        p{
          text-indent: 2ch;
          font-size: 5.5px;
          color: #1E4743;
          line-height: 10px;
        }
        .pTitle{
          font-weight: bold;
        }
        .divTitle{
          font-size: 5.5px;
          color: #1E4743;
          margin-top: 15px;
        }
        .paTitle{
          margin-top: 10px;
        }
        .descD, .timer{
          font-size: 5.5px;
          color: #1E4743;
          margin-top: 15px;
          text-align: right;
        }
        .descD{
          margin-top: 15px;
        }
        .timer{
          margin-top: 5px;
          margin-bottom: 20px;
        }


      }
    }
  }
}
</style>