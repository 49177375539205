<!--
 * @Author: 1697482796@qq.com 1697482796@qq.com
 * @Date: 2023-02-08 12:24:15
 * @LastEditors: 1697482796@qq.com 1697482796@qq.com
 * @LastEditTime: 2023-03-31 17:59:52
 * @FilePath: \sdy_webpack1\src\pages\deptDetail.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="deptDetail">
    <!-- 背景图 -->
    <div class="backgrpund_img"></div>
    <div class="blackDiv"></div>
    <!-- 内容 -->
    <div class="optionContainer">
      <div class="positionDiv">
        您的位置：
        <span class="cursorSpan" @click="goIndex">医院首页</span>
        &nbsp;&gt;&nbsp;所有科室&nbsp;&gt;&nbsp;{{ deptInfo.name }}
      </div>
      <h1 class="deptName">{{ deptInfo.name }}</h1>
      <!-- 科室内容 -->
      <div class="deptInfos">
        <h1 class="deptTitle">科室简介</h1>
        <div class="deptDescs">
          <div class="descTextDiv">
            <div class="descText">{{ deptInfo.departmentDesc }}</div>
            <div class="descBtns">
              <button
                class="detailBtn"
                @click="detailInfoClick(deptInfo, 'dept')"
              >
                <i class="el-icon-more"></i>查看详情
              </button>
              <button class="appoBtn" @click="dialogVisible = true">
                <i class="el-icon-date"></i>预约科室
              </button>
            </div>
          </div>
          <div class="deptImgDiv" v-if="deptInfo.photo">
            <img
              v-if="deptInfo.photo.data !== null"
              :src="imgUrl + deptInfo.photo.data.attributes.url"
              alt
            />
            <div v-else class="noImg">暂无科室图片</div>
          </div>
        </div>
      </div>
      <!-- 科室专家 -->
      <div class="deptZJContainer">
        <div class="zjTopInfos">
          <img class="titleImg" src="../assets/icons/detailzj.png" alt />
          <span class="title">科室专家</span>
          <!-- <span class="text">国务院政府特贴专家、主任医师</span> -->
          <!-- <div class="listMore" @click="moreClick">更多<img src="../assets/icons/list_more.png" alt=""></div> -->
        </div>
        <div class="infoContent">
          <div class="zjIntInfoLists" v-if="zjList.length != 0">
            <div
              class="infoFlex"
              v-for="(item, index) in zjList"
              :key="index"
              @click="expertDetailClick(item)"
            >
              <div class="zjList" v-if="index % 2 !== 0">
                <div class="infoLeft">
                  <div class="doctorName">
                    {{ item.attributes.doctorName }}
                  </div>
                  <div class="txDiv titleDiv">
                    <!-- <span class="title txTitle">医生职称</span> -->
                    <div class="text txText">
                      {{ item.attributes.doctorTitle }}
                    </div>
                  </div>
                  <div class="yjDiv titleDiv">
                    <!-- <span class="title yjTitle">主要研究</span> -->
                    <div class="text yjText">
                      {{ item.attributes.expertise }}
                    </div>
                  </div>
                  <div class="timerDiv">
                    <!-- <span class="title tmTitle">出诊时间</span> -->
                    <!-- <span class="text timeText">
                      {{ item.attributes.visitTime }}
                    </span> -->
                  </div>
                </div>
                <div class="imgDiv">
                  <img
                    v-if="item.attributes.photo.data"
                    :src="imgUrl + item.attributes.photo.data.attributes.url"
                    alt=""
                  />
                  <div v-else class="imgNo">暂无照片</div>
                </div>
              </div>
              <div class="zjList" v-else>
                <div class="imgDiv">
                  <img
                    v-if="item.attributes.photo.data"
                    :src="imgUrl + item.attributes.photo.data.attributes.url"
                    alt
                  />
                  <div v-else class="imgNo">暂无照片</div>
                  <!-- <div class="zjName">{{ item.attributes.doctorName }}</div> -->
                </div>
                <div class="infoRight">
                  <div class="doctorName">
                    {{ item.attributes.doctorName }}
                  </div>
                  <div class="txDiv titleDiv">
                    <!-- <span class="title txTitle">医生职称</span> -->
                    <div class="text txText">
                      {{ item.attributes.doctorTitle }}
                    </div>
                  </div>
                  <div class="yjDiv titleDiv">
                    <!-- <span class="title yjTitle">主要研究</span> -->
                    <div class="text yjText">
                      {{ item.attributes.expertise }}
                    </div>
                  </div>
                  <div class="timerDiv">
                    <!-- <span class="title tmTitle">出诊时间</span> -->
                    <!-- <span class="text timeText">{{ item.attributes.visitTime }}</span> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="nodataImg" v-else>
            <img src="../assets/icons/nodata.png" alt />
          </div>
        </div>
      </div>
    </div>
    <!-- 弹框 -->
    <el-dialog
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
    >
      <div class="eqCodeDiv">
        <div class="eqImg">
          <img src="../assets/icons/wxzy.png" alt />
        </div>
        <div class="eqtext">
          <div>使用手机微信，扫描二维码</div>
          <div>进入新疆人民医院掌上医院小程序</div>
          <div>手机挂号、缴费退费、查看报告</div>
        </div>
        <!-- <div class="eqtext">扫描二维码进行科室预约</div> -->
      </div>
    </el-dialog>
    <!-- 底部 -->
    <Footer></Footer>
  </div>
</template>

<script>
import axios from "axios";
import Footer from "@/components/footer.vue";
import { config } from "../api/config";
import { IsPC } from "@/utils/agentFlag";
export default {
  name: "deptDetail",
  components: {
    Footer,
  },
  data() {
    return {
      imgUrl: "",
      dialogVisible: false,
      deptName: "",
      deptId: "",
      deptInfo: {},
      zjList: [],
    };
  },
  created() {
    this.imgUrl = config().imgserve;
    this.deptInfo = {};
    this.deptName = this.$route.query.list;
    this.deptId = this.$route.query.id;
    if (!IsPC()) {
      this.$router.push({
        path: "/deptDetailMob",
        query: {
          list: this.deptName,
          id: this.deptId,
        },
      });
      return;
    }
    this.getDeptDetail();
  },
  methods: {
    // 获取科室信息
    getDeptDetail() {
      axios
        .get(
          config().urlServe +
            "/api/departments/" +
            this.deptId +
            "?populate[photo]=true"
        )
        .then((res) => {
          if (res.status === 200) {
            if (res.data.data) {
              this.deptInfo = res.data.data.attributes;
              document.title = "新疆维吾尔自治区人民医院-" + this.deptInfo.name;
            }
            // console.log('参数', this.deptInfo)
            this.getDeptExper();
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    // 获取科室专家
    getDeptExper() {
      this.zjList = [];
      axios
        .get(
          config().urlServe +
            "/api/doctors?pagination[pageSize]=1000&pagination[page]=1&populate=*&sort=sortNo:desc&filters[departments][name][$eq]=" +
            this.deptInfo.name
        )
        .then((res) => {
          if (res.status === 200) {
            this.zjList = res.data.data;
            // console.log('999888', this.zjList)
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    // 专家介绍
    expertDetailClick(e) {
      this.$router.push({
        path: "/expertDetail",
        query: {
          list: e.attributes.doctorName,
          id: e.id,
        },
      });
    },
    // 关闭科室预约
    handleClose() {
      this.dialogVisible = false;
    },
    detailInfoClick(e, type) {
      // 判断是否跳转外部链接
      if (e.attributes.link) {
        window.location.href = e.attributes.link
      } else {
        this.$router.push({
        path: "/deptDetailInfo",
        query: {
          list: e.name,
          type: type,
          id: this.deptId,
        },
      });
      }
    },
    // 返回首页
    goIndex() {
      this.$router.push({
        path: "/index",
      });
    },
    moreClick() {},
  },
};
</script>
<style>
.el-dialog__title {
  font-size: 6px !important;
}
.el-dialog__header {
  padding: 10px 10px 0px !important;
}
.el-dialog__body {
  padding: 10px !important;
}
.el-dialog__headerbtn {
  font-size: 7px !important;
}
</style>

<style lang="less" scoped>
// body {
//   margin: 0;
//   background: #eff2f4 !important;
//   min-width: 620px;
// }
// @media screen and (max-width: 5000px) {
//   /deep/ .el-dialog {
//     width: 50% !important;
//   }
//   .optionContainer {
//     width: 80%;
//   }
//   .deptImgDiv {
//     height: 380px;
//     img {
//       height: 380px;
//     }
//     .noImg {
//       height: 380px;
//       line-height: 380px;
//     }
//   }
//   .zjList {
//     .imgDiv {
//       height: 203px;
//       width: 145px;
//       img {
//         height: 203px;
//         width: 145px;
//       }
//       .zjName {
//         height: 35px;
//         line-height: 35px;
//       }
//     }
//     .infoLeft {
//       height: 200px;
//     }
//     .infoRight {
//       height: 200px;
//     }
//     .text {
//       font-size: 15px;
//     }
//     .txText {
//       max-height: 50px;
//     }
//     .yjText {
//       max-height: 65px;
//     }
//   }
// }
// @media screen and (max-width: 1500px) {
//   .optionContainer {
//     width: 90%;
//   }
//   // .imgDiv {
//   //     height: 203px;
//   //     width: 145px;
//   //     img {
//   //       height: 203px;
//   //       width: 145px;
//   //     }
//   //     .zjName {
//   //       height: 35px;
//   //       line-height: 35px;
//   //     }
//   //   }
// }
// @media screen and (max-width: 900px) {
//   /deep/ .el-dialog {
//     width: 80% !important;
//   }
//   .optionContainer {
//     width: 98%;
//   }
//   .deptImgDiv {
//     height: 280px;
//     img {
//       height: 280px;
//     }
//     .noImg {
//       height: 280px;
//       line-height: 280px;
//     }
//   }
//   .zjList {
//     .imgDiv {
//       height: 133px;
//       img {
//         height: 115px;
//       }
//       .zjName {
//         height: 25px;
//         line-height: 25px;
//       }
//     }
//     .infoLeft {
//       height: 180px;
//     }
//     .infoRight {
//       height: 180px;
//     }
//     .text {
//       font-size: 12px;
//     }
//     .txText {
//       max-height: 30px;
//     }
//     .yjText {
//       max-height: 45px;
//     }
//   }
// }
h1 {
  margin: 0px;
  padding: 0px;
}
.nodataImg {
  widows: 100%;
  text-align: center;
  margin-top: 10%;
  margin-bottom: 10%;
  img {
    width: 58px;
  }
}
.deptDetail {
  width: 100%;
  position: relative;
  .backgrpund_img {
    position: absolute;
    background: #dff1f5;
    top: 0px;
    height: 100px;
    width: 100%;
    background-image: url("../assets/images/h_xj_bg.png");
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .blackDiv {
    height: 10px;
  }
  .listMore {
    position: absolute;
    z-index: 100;
    color: #049d98;
    font-size: 14px;
    width: 75px;
    height: 28px;
    text-align: center;
    line-height: 28px;
    border: 2px solid #049d98;
    border-radius: 15px;
    right: 15px;
    top: 5px;
    img {
      width: 20px;
      position: relative;
      top: 4px;
      left: 2px;
    }
  }
  .optionContainer {
    width: 80%;
    margin: 0 auto;
    position: relative;
    text-align: left;
    .cursorSpan:hover {
      cursor: pointer;
      color: #049d98;
    }
    .positionDiv {
      font-size: 6px;
    }
    .deptName {
      font-size: 8px;
      margin: 10px 0;
    }
    // 科室内容
    .deptInfos {
      width: calc(100% - 20px);
      padding: 10px;
      border-radius: 3px;
      background: #049d98;
      box-shadow: 0px 0px 3px 2px #aaa;
      color: #fff;
      .deptTitle {
        margin-bottom: 5px;
      }
      .deptDescs {
        width: 100%;
        height: 90px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        .descTextDiv {
          width: 55%;
          margin-right: 10px;
          .descText {
            height: 70px;
            box-sizing: border-box;
            line-height: 10px;
            font-size: 5px;
            color: #e9edef;
            // background-color: cadetblue;
            // 多行省略
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 6;
            overflow: hidden;
            margin-bottom: 10px;
          }
          .descBtns {
            button {
              width: 30px;
              text-align: center;
              font-size: 5px;
              height: 10px;
              line-height: 10px;
              border-radius: 4px;
              border: none;
              background: none;
              color: rgb(255, 255, 255);
            }
            .detailBtn {
              background-color: rgba(255, 255, 255, 0.5);
              margin-right: 10px;
            }
            .appoBtn {
              background-color: #dd5b25;
            }
          }
        }
        .deptImgDiv {
          width: 45%;
          height: 120px;
          border-radius: 3px;
          position: relative;
          background: #eeeded;
          overflow: hidden;
          box-shadow: 0px 0px 3px 2px #aaa;
          margin-top: -10px;
          img {
            width: 100%;
            height: 120px;
          }
          .noImg {
            color: #999;
            width: 100%;
            text-align: center;
            font-size: 6px;
            height: 120px;
            line-height: 120px;
          }
        }
      }
    }
    // 科室专家
    .deptZJContainer {
      margin-top: 15px;
      width: calc(100% - 10px);
      background: #dff1f5;
      border-radius: 3px;
      padding: 10px 5px;
      .zjTopInfos {
        width: 100%;
        position: relative;
        .titleImg {
          width: 20px;
          position: absolute;
          top: 0px;
          left: 0px;
        }
        span {
          display: inline-block;
        }
        .title {
          margin-left: 25px;
          font-size: 8px;
          font-weight: bold;
          margin-top: 5px;
          color: #049d98;
        }
        .text {
          font-size: 4px;
          margin-left: 3px;
        }
      }
      .infoContent {
        width: 100%;
        max-height: 245px;
        overflow: hidden;
      }
      .zjIntInfoLists {
        width: calc(100% - 10px);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 10px;
        max-height: 230px;
        overflow: hidden;
        overflow-y: auto;
        padding: 5px;
        .infoFlex {
          width: 49%;
        }
        .zjList {
          widows: 100%;
          // border-radius: 3px;
          box-shadow: 0px 0px 2px 1px #d1d0d0;
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-between;
          margin-bottom: 10px;
          // padding: 10px 5px;
          // height: 123px;
          // .imgDiv {
          //       height: 203px;
          //       width: 145px;
          //       img {
          //         height: 203px;
          //         width: 145px;
          //       }
          .imgDiv {
            height: 70px;
            width: 50px;
            // width: 21%;
            // border-radius: 3px;
            overflow: hidden;
            position: relative;
            border: 0.1px solid #d1d0d0;
            box-sizing: border-box;
            img {
              width: 100%;
            }
            .imgNo {
              width: 100%;
              line-height: 70px;
              text-align: center;
              color: rgba(39, 41, 41, 0.4);
              font-size: 5px;
            }
            .zjName {
              width: 100%;
              height: 13px;
              line-height: 13px;
              position: absolute;
              bottom: 0px;
              background-color: rgba(39, 41, 41, 0.4);
              text-align: center;
              color: #fff;
              font-size: 6px;
            }
          }
          .infoLeft {
            width: calc(79% - 10px);
            margin-right: 5px;
            position: relative;
            padding: 3px 0px;
            margin-left: 5px;
          }
          .infoRight {
            width: calc(79% - 10px);
            margin-left: 5px;
            position: relative;
            padding: 3px 0px;
            margin-right: 5px;
          }
          .doctorName {
            color: #01312f;
            font-size: 8px;
            font-weight: bold;
            margin-bottom: 5px;
            margin-top: 2px;
          }
          .timerDiv {
            position: absolute;
            bottom: 0px;
          }
          .title {
            display: inline-block;
            width: 20px;
            text-align: center;
            height: 8px;
            line-height: 8px;
            border-radius: 5px;
            color: #fff;
            font-size: 4px;
          }
          .txTitle {
            background-color: #049d98;
          }
          .yjTitle {
            background-color: #2f75bc;
          }
          .tmTitle {
            background-color: #dd5b25;
          }
          .text {
            color: #5b5b5b;
            line-height: 8px;
            margin-top: 2px;
            font-size: 4.4px;
          }
          .txText {
            margin-bottom: 3px;
            // 多行省略
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          .yjText {
            // 多行省略
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            overflow: hidden;
          }
        }
      }
      .zjIntInfoLists::-webkit-scrollbar {
        width: 1px;
      }
      .zjIntInfoLists::-webkit-scrollbar-thumb {
        background-color: #049d98;
      }
    }
  }
  .eqCodeDiv {
    .eqImg {
      display: flex;
      width: 100%;
      font-weight: normal;
      justify-content: space-around;
    }
    img {
      width: 30%;
    }
    .eqtext {
      font-size: 6px;
      color: #999;
      margin-top: 5px;
      div {
        width: 100%;
        text-align: center;
      }
    }
  }
}
</style>