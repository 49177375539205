import Vue from 'vue'
import store from './store'
import App from './App.vue'
import Router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './utils/rem'
import 'lib-flexible'
Vue.use(ElementUI)
// 引入axios
import axios from 'axios'
Vue.prototype.$axios = axios

Vue.config.productionTip = false

new Vue({
  store,
  router: Router,
  render: h => h(App),
}).$mount('#app')
