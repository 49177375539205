/*
 * @Author: 1697482796@qq.com 1697482796@qq.com
 * @Date: 2023-02-02 12:43:43
 * @LastEditors: 1697482796@qq.com 1697482796@qq.com
 * @LastEditTime: 2023-03-30 10:32:41
 * @FilePath: \sdy_webpack\src\api\config.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export function config() {
    // let url = window.location.protocol + '//' + window.location.host
    // https://www.sdyfy.com.cn/
    // 配置域名
    let urls = {
        // urlServe: 'http://192.168.110.13:1337',
        // imgserve: 'http://192.168.110.13:1337',
        urlServe: 'https://www.xjrmyy.com',
        imgserve: 'https://www.xjrmyy.com',
        allImahes: '',
        appKey: '',
        version: ''
    }
    return urls;
}