<!--
 * @Author: 1697482796@qq.com 1697482796@qq.com
 * @Date: 2023-02-08 12:24:15
 * @LastEditors: 1697482796@qq.com 1697482796@qq.com
 * @LastEditTime: 2023-03-31 17:59:52
 * @FilePath: \sdy_webpack1\src\pages\deptDetail.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="deptDetail">
    <!-- 背景图 -->
    <!-- <div class="backgrpund_img"></div>
    <div class="blackDiv"></div> -->
    <!-- 内容 -->
    <div class="optionContainer">
      <!-- <h1 class="deptName">内科科室</h1> -->
      <!-- <h1 class="deptName">{{ deptInfo.name }}</h1> -->
      <!-- 科室内容 -->
      <div class="deptInfos">
        <h1 class="deptTitle">{{ deptInfo.name }}</h1>
        <div class="descBtns">
          <button class="detailBtn" @click="detailInfoClick(deptInfo, 'dept')">
            <i class="el-icon-more"></i>查看详情
          </button>
        </div>
        <div class="deptDescs">
          <div class="descTextDiv">
            <!-- {{ deptInfo.departmentDesc }} -->
            <div class="descText">{{ deptInfo.departmentDesc }}</div>
          </div>
          <div class="deptImgDiv" v-if="deptInfo.photo">
            <img
              v-if="deptInfo.photo.data !== null"
              :src="imgUrl + deptInfo.photo.data.attributes.url"
              alt
            />
            <div v-else class="noImg">暂无科室图片</div>
          </div>
        </div>
      </div>
      <!-- 科室专家 -->
      <div class="deptZJContainer">
        <div class="zjTopInfos">
          <img class="titleImg" src="../assets/icons/detailzj.png" alt />
          <span class="title">科室专家</span>
          <!-- <span class="text">国务院政府特贴专家、主任医师</span> -->
          <!-- <div class="listMore" @click="moreClick">更多<img src="../assets/icons/list_more.png" alt=""></div> -->
        </div>
        <div class="infoContent">
          <div class="zjIntInfoLists" v-if="zjList.length != 0">
            <div
              class="infoFlex"
              v-for="(item, index) in zjList"
              :key="index"
              @click="expertDetailClick(item)"
            >
              <!-- <div class="zjList" v-if="index % 2 !== 0">
                <div class="infoLeft">
                  <div class="txDiv titleDiv">
                    <span class="title txTitle">医生职称</span>
                    <div class="text txText">
                      医学博士、心血管内科专业、主任医师
                    </div>
                  </div>
                  <div class="yjDiv titleDiv">
                    <span class="title yjTitle">主要研究</span>
                    <div class="text yjText">
                      2017年入选“国家百千万人才工程”并被授予“有突出贡献中青年专家”荣誉称号，第十二届“中国医师奖”获得者，先后入选教育部新世纪优秀人才计划，自治区“天山雪松计划”科技创新领军人才后备，“天山英才”培养计划第一层次。2017年入选新疆自然科学专家库。
                                        2004年和2007年分别于新加坡国立大学医院导管室和德国Mainz Johannes
                                        Gutenberg大学临床医院心内科研修冠状动脉介入治疗。目前研究方向为冠心病、先天性心脏病及瓣膜病的介入治疗及相关基础研究。杨毅宁教授在临床工作中不断开拓创新，在疑难危重症治疗上，开展和推广IVUS、OCT、远隔缺血适应治疗、TAVR、ECMO等新技术。
                                        主持国家重点研发计划1项，国家自然科学基金6项，教育部新世纪优秀人才计划项目1项等20余项研究。
                    </div>
                  </div>
                  <div class="timerDiv">
                    <span class="title tmTitle">出诊时间</span>
                    <span class="text timeText">
                      每周一全天
                    </span>
                  </div>
                </div>
                <div class="imgDiv">
                  <img
                    src="../assets/images/h_zj_img.jpg"
                    alt=""
                  />
                  <div class="zjName">李南方</div>
                </div>
              </div> -->
              <div class="zjList">
                <div class="imgDiv">
                  <img
                    v-if="item.attributes.photo.data"
                    :src="imgUrl + item.attributes.photo.data.attributes.url"
                    alt
                  />
                  <div class="imgDivNo">暂无照片</div>
                </div>
                <div class="infoRight">
                  <div class="doctorName">
                    {{ item.attributes.doctorName }}
                  </div>
                  <div class="txDiv titleDiv">
                    <!-- <span class="title txTitle">医生职称</span> -->
                    <div class="text txText">
                      {{ item.attributes.doctorTitle }}
                    </div>
                  </div>
                  <div class="yjDiv titleDiv">
                    <!-- <span class="title yjTitle">主要研究</span> -->
                    <div class="text yjText">
                      {{ item.attributes.expertise}}
                    </div>
                  </div>
                  <!-- <div class="timerDiv">
                    <span class="title tmTitle">出诊时间</span>
                    <span class="text timeText">{{
                      item.attributes.visitTime
                    }}</span>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <div class="nodataImg" v-else>
            <img src="../assets/icons/nodata.png" alt />
          </div>
        </div>
      </div>
    </div>
    <!-- 弹框 -->
    <el-dialog
      title="小程序二维码"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
    >
      <div class="eqCodeDiv">
        <div class="eqImg">
          <img src="../assets/icons/eyydy.png" alt />
          <img src="../assets/icons/wxzy.png" alt />
        </div>
        <div class="eqtext">扫描二维码进行科室预约</div>
      </div>
    </el-dialog>
    <!-- 底部 -->
    <FooterMob></FooterMob>
  </div>
</template>

<script>
import axios from "axios";
import FooterMob from "@/components/footerMob.vue";
import { config } from "../api/config";
import { IsPC } from "@/utils/agentFlag";
export default {
  name: "deptDetailMob",
  components: {
    FooterMob,
  },
  data() {
    return {
      imgUrl: "",
      dialogVisible: false,
      deptName: "",
      deptId: "",
      deptInfo: {},
      zjList: [],
    };
  },
  created() {
    this.imgUrl = config().imgserve;
    this.deptInfo = {};
    this.deptName = this.$route.query.list;
    this.deptId = this.$route.query.id;
    if (IsPC()) {
      this.$router.push({
        path: "/deptDetail",
        query: {
          list: this.deptName,
          id: this.deptId,
        },
      });
      return;
    }
    this.getDeptDetail();
  },
  methods: {
    // 获取科室信息
    getDeptDetail() {
      axios
        .get(
          config().urlServe +
            "/api/departments/" +
            this.deptId +
            "?populate[photo]=true"
        )
        .then((res) => {
          if (res.status === 200) {
            this.deptInfo = res.data.data.attributes;
            document.title = "新疆维吾尔自治区人民医院-" + this.deptInfo.name;
            // console.log('88888', this.deptInfo)
            this.getDeptExper();
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    // 获取科室专家
    getDeptExper() {
      this.zjList = [];
      axios
        .get(
          config().urlServe +
            "/api/doctors?pagination[pageSize]=1000&pagination[page]=1&populate=*&sort=sortNo:desc&filters[departments][name][$eq]=" +
            this.deptInfo.name
        )
        .then((res) => {
          if (res.status === 200) {
            // console.log('专家', res)
            this.zjList = res.data.data;
          }
        })
        .catch((err) => {
          alert(err);
        });
    },
    // 专家介绍
    expertDetailClick(e) {
      this.$router.push({
        path: "/expertDetailMob",
        query: {
          list: e.attributes.doctorName,
          id: e.id,
        },
      });
    },
    // 关闭科室预约
    handleClose() {
      this.dialogVisible = false;
    },
    detailInfoClick(e, type) {
      // 判断是否跳转外部链接
      if (e.attributes.link) {
        window.location.href = e.attributes.link
      } else {
        this.$router.push({
          path: "/deptDetailInfoMob",
          query: {
            list: e.name,
            type: type,
            id: this.deptId,
          },
        });
      }
    },
    // 返回首页
    goIndex() {
      this.$router.push({
        path: "/index",
      });
    },
    moreClick() {},
  },
};
</script>

<style lang="less" scoped>
h1 {
  margin: 0px;
  padding: 0px;
}
.nodataImg {
  widows: 100%;
  text-align: center;
  margin-top: 10%;
  margin-bottom: 10%;
  img {
    width: 58px;
  }
}
.deptDetail {
  width: 100%;
  position: relative;
  margin-top: 40px;
  .listMore {
    position: absolute;
    z-index: 100;
    color: #049d98;
    font-size: 12px;
    width: 75px;
    height: 28px;
    text-align: center;
    line-height: 28px;
    border: 2px solid #049d98;
    border-radius: 15px;
    right: 15px;
    top: 5px;
    img {
      width: 20px;
      position: relative;
      top: 4px;
      left: 2px;
    }
  }
  .optionContainer {
    width: 100%;
    margin: 0 auto;
    position: relative;
    text-align: left;
    .cursorSpan:hover {
      cursor: pointer;
      color: #049d98;
    }
    .positionDiv {
      font-size: 6px;
    }
    .deptName {
      font-size: 8px;
      margin: 10px 0;
    }
    // 科室内容
    .deptInfos {
      width: calc(100% - 10px);
      background: #dff1f5;
      border-radius: 3px;
      padding: 10px 5px;
      // box-shadow: 0px 0px 3px 2px #aaa;
      color: #1e4743;
      position: relative;
      .deptTitle {
        width: 83%;
        margin-bottom: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .deptDescs {
        width: 100%;
        // height: 90px;
        // display: flex;
        // flex-wrap: nowrap;
        // justify-content: space-between;
        .descTextDiv {
          width: 100%;
          margin-right: 10px;
          .descText {
            max-height: 60px;
            box-sizing: border-box;
            line-height: 10px;
            font-size: 5px;
            color: #1e4743;
            // background-color: cadetblue;
            // 多行省略
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 6;
            overflow: hidden;
            margin-bottom: 10px;
          }
        }

        .deptImgDiv {
          width: 100%;
          // height: 120px;
          border-radius: 3px;
          position: relative;
          background: #fff;
          overflow: hidden;
          box-shadow: 0px 0px 1px 0.2px #aaa;
          margin-top: -10px;
          img {
            width: 100%;
            height: 120px;
          }
          .noImg {
            color: #999;
            width: 100%;
            text-align: center;
            font-size: 5px;
            line-height: 60px;
          }
        }
      }
      .descBtns {
        position: absolute;
        top: 12px;
        right: -5px;
        button {
          // width: 30px;
          text-align: center;
          font-size: 5px;
          height: 10px;
          line-height: 10px;
          border-radius: 4px;
          border: none;
          background: none;
          color: rgb(255, 255, 255);
        }
        .detailBtn {
          background-color: rgba(8, 146, 145, 0.8);
          margin-right: 10px;
        }
        .appoBtn {
          background-color: #dd5b25;
        }
      }
    }
    // 科室专家
    .deptZJContainer {
      margin-top: 5px;
      width: calc(100% - 10px);
      background: #dff1f5;
      border-radius: 3px;
      padding: 5px 5px;
      .zjTopInfos {
        width: 100%;
        position: relative;
        .titleImg {
          width: 15px;
          position: absolute;
          top: 0px;
          left: 0px;
        }
        span {
          display: inline-block;
        }
        .title {
          margin-left: 17px;
          font-size: 8px;
          font-weight: bold;
          margin-top: 2px;
          color: #049d98;
        }
        .text {
          font-size: 4px;
          margin-left: 3px;
        }
      }
      .infoContent {
        width: 100%;
        max-height: 245px;
        overflow: hidden;
      }
      .zjIntInfoLists {
        width: calc(100% - 10px);
        // display: flex;
        // flex-wrap: wrap;
        // justify-content: space-between;
        margin-top: 3px;
        max-height: 230px;
        overflow: hidden;
        overflow-y: auto;
        padding: 5px;
        .infoFlex {
          width: 100%;
        }
        .zjList {
          widows: 100%;
          border-radius: 3px;
          box-shadow: 0px 0px 2px 1px #d1d0d0;
          display: flex;
          flex-wrap: nowrap;
          justify-content: space-between;
          margin-bottom: 5px;
          padding: 5px;
          // height: 123px;
          // .imgDiv {
          //       height: 203px;
          //       width: 145px;
          //       img {
          //         height: 203px;
          //         width: 145px;
          //       }
          .imgDiv {
            height: 55px;
            width: 50px;
            // width: 21%;
            // border-radius: 3px;
            overflow: hidden;
            position: relative;
            box-shadow: 0px 0px 2px 1px #d1d0d0;
            .imgDivNo {
              width: 100%;
              line-height: 55px;
              text-align: center;
              font-size: 4px;
              color: #d1d0d0;
            }
            img {
              width: 100%;
            }
            .zjName {
              width: 100%;
              height: 13px;
              line-height: 13px;
              position: absolute;
              bottom: 0px;
              background-color: rgba(39, 41, 41, 0.4);
              text-align: center;
              color: #fff;
              font-size: 6px;
            }
          }
          .infoLeft {
            width: calc(79% - 10px);
            margin-right: 10px;
            position: relative;
          }
          .infoRight {
            width: calc(79% - 10px);
            margin-left: 10px;
            position: relative;
          }
          .doctorName {
            color: #01312f;
            font-size: 6px;
            font-weight: bold;
            margin-bottom: 3px;
            margin-top: 2px;
          }
          .timerDiv {
            position: absolute;
            bottom: 0px;
          }
          .title {
            display: inline-block;
            width: 20px;
            text-align: center;
            height: 8px;
            line-height: 8px;
            border-radius: 5px;
            color: #fff;
            font-size: 4px;
          }
          .txTitle {
            background-color: #049d98;
          }
          .yjTitle {
            background-color: #2f75bc;
          }
          .tmTitle {
            background-color: #dd5b25;
          }
          .text {
            color: #5b5b5b;
            line-height: 8px;
            margin-top: 2px;
            font-size: 4.4px;
          }
          .txText {
            margin-bottom: 3px;
            // 多行省略
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          .yjText {
            // 多行省略
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 4;
            overflow: hidden;
          }
        }
      }
      .zjIntInfoLists::-webkit-scrollbar {
        width: 1px;
      }
      .zjIntInfoLists::-webkit-scrollbar-thumb {
        background-color: #049d98;
      }
    }
  }
  .eqCodeDiv {
    .eqImg {
      display: flex;
      width: 100%;
      font-weight: normal;
      justify-content: space-around;
    }
    img {
      width: 30%;
    }
    .eqtext {
      font-size: 6px;
      color: #999;
      margin-top: 5px;
    }
  }
}
</style>