/*
 * @Author: 1697482796@qq.com 1697482796@qq.com
 * @Date: 2023-02-02 12:03:51
 * @LastEditors: 1697482796@qq.com 1697482796@qq.com
 * @LastEditTime: 2023-02-20 23:09:49
 * @FilePath: \sdy_webpack1\src\store\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    allMenuList: [],
    menuCurObj: {},
  },
  mutations: {
    updataAllMenuList(state, info) {
      sessionStorage.setItem("allMenuList", JSON.stringify(info));
      state.allMenuList = info;
    },
    updataMenuCurObj(state, info) {
      sessionStorage.setItem("menuCurObj", JSON.stringify(info));
      state.menuCurObj = info;
    },
  },
  actions: {},
});

export default store;
