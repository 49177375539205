import Vue from "vue";
import Router from "vue-router";

// 修改push方法 解决vue-router跳转同一路由时报错问题
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((error) => error);
};

// 引入组件
import index from "../pages/index.vue"
import deptDetailInfo from "@/pages/deptDetailInfo.vue";
import deptDetailInfoMob from "@/pages/deptDetailInfoMob.vue";
import expertDetail from "@/pages/expertDetail.vue";
import expertDetailMob from "@/pages/expertDetailMob.vue";
import deptDetail from "@/pages/deptDetail.vue";
import deptDetailMob from "@/pages/deptDetailMob.vue";
import optionList from "@/pages/optionList.vue";
import optionListMob from "@/pages/optionListMob.vue"
import searchPage from "@/pages/searchPage.vue"
import searchPageMob from "@/pages/searchPageMob.vue"
import exportList from "@/pages/exportList.vue"
import exportListMob from "@/pages/exportListMob.vue"
Vue.use(Router);
// 创造router实例并配置路由
const routes = [
  {
    path: "/",
    redirect: "/index"
  },
  {
    path: "/index",
    name: "index",
    component: index
  },
  {
    path: "/deptDetailInfo",
    name: "deptDetailInfo",
    component: deptDetailInfo,
  },
  {
    path: "/expertDetail",
    name: "expertDetail",
    component: expertDetail,
  },
  {
    path: "/deptDetail",
    name: "deptDetail",
    component: deptDetail,
  },
  {
    path: "/optionList",
    name: "optionList",
    component: optionList,
  },
  {
    path: "/optionListMob",
    name: "optionListMob",
    component: optionListMob,
  },
  {
    path: "/expertDetailMob",
    name: "expertDetailMob",
    component: expertDetailMob,
  },
  {
    path: "/deptDetailMob",
    name: "deptDetailMob",
    component: deptDetailMob,
  },
  {
    path: "/deptDetailInfoMob",
    name: "deptDetailInfoMob",
    component: deptDetailInfoMob,
  },
  {
    path: "/searchPage",
    name: "searchPage",
    component: searchPage
  },
  {
    path: "/searchPageMob",
    name: "searchPageMob",
    component: searchPageMob
  },
  {
    path: "/exportList",
    name: "exportList",
    component: exportList
  },
  {
    path: "/exportListMob",
    name: "exportListMob",
    component: exportListMob
  }
]
export default new Router({
  routes
})