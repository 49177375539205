<template>
  <div class="footerContainer">
    <div class="footer">
      <div class="footerTop">
        <div class="f-t-l">
          <div>电 话：<span>0991-8562209[值班]</span></div>
          <div>邮 编：<span>830001</span></div>
          <div>传 真：<span>0991-8564761</span></div>
          <div>地 址: <span>中国新疆乌鲁木齐市天池路91号</span></div>
          <div>邮 箱：<span>postmaster@xjrmyy.com</span></div>
          <div>版权所有：<span><a href="https://beian.miit.gov.cn/">新ICP备12003644号-9</a></span></div>
          <!-- <div>备案号：<span>postmaster@xjrmyy.com</span></div> -->
        </div>
        <div class="f-t-r">
          <div><img src="../assets/icons/eyydy.png" alt=""><div class="imgTitle">官方抖音</div></div>
          <div><img src="../assets/icons/wxzy.png" alt=""><div class="imgTitle">掌上医院</div></div>
          <div><img src="../assets/icons/hlweq.png" alt=""><div class="imgTitle">互联网医院</div></div>
        </div>
      </div>
      <!-- <div class="footerBottom">
        <img src="../assets/images/logo_str_bg.png" alt="">
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  }
};
</script>

<style lang="less" scoped>
.footerContainer {
  width: 100%;
  // background: #eff2f4;
  margin-top: 20px;
  padding-bottom: 25px;
  // height: 100px;
  // background-image: linear-gradient(to bottom, #eff2f4, rgb(132, 134, 136));
  background-image: url('../assets/images/h_footer_bg.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 100% 100%;
  position: relative;
  .footer{
    width: 65%;
    margin: 0 auto;
    // background: #999;
    .footerTop{
      width: 100%;
      display: flex;
      justify-content: space-between;
      .f-t-l{
        width: 60%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        // background: #616060;
        margin-top: 25px;
        div{
          width: 50%;
          color: #A3B4B4;
          font-size: 5px;
          margin-bottom: 5px;
          span{
            color: #eee;
            letter-spacing: 0.3px;
            a{
              color: #8dcaca;
            }
          }
        }
      }
      .f-t-r{
        // width: 30%;
        display: flex;
        justify-content: space-between;
        // background: #bb8989;
        margin-top: 21px;
        img{
          width: 35px;
          height: 35px;
          margin-left: 15px;
        }
        .imgTitle{
          width: 100%;
          text-align: center;
          margin-left: 7px;
          color: #A3B4B4;
          font-size: 4.5px;
          margin-top: 2px;
        }
      }
    }
    .footerBottom{
      width: 70%;
      margin: 15px auto;
      img{
        width: 100%;
      }
    }
  }
 
}
</style>
